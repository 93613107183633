img:is([sizes="auto" i], [sizes^="auto," i]) {
  contain-intrinsic-size: 3000px 1500px;
}

.panel-grid.panel-no-style {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: nowrap;
  -ms-justify-content: space-between;
  justify-content: space-between;
}

.panel-grid-cell {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.panel-grid-cell .so-panel {
  zoom: 1;
}

.panel-grid-cell .so-panel:before {
  content: "";
  display: block;
}

.panel-grid-cell .so-panel:after {
  content: "";
  display: table;
  clear: both;
}

.panel-grid-cell .panel-last-child {
  margin-bottom: 0;
}

body.siteorigin-panels-before-js:not(.siteorigin-panels-css-container) {
  overflow-x: hidden;
}

body {
  background-color: #fff;
}

#CybotCookiebotDialog[name="CybotCookiebotDialog"],
.form .gform_wrapper .gform_footer input[type="submit"],
.search--new__field,
.search__field,
body {
  font-family: "Proxima Nova", Arial, SourceSansPro, "Segoe UI", Roboto,
    "Helvetica Neue", sans-serif;
}

body,
html {
  overflow-x: hidden;
}

.link,
body {
  position: relative;
}

.description {
  text-align: center;
}

.description a,
.link,
.link-n {
  text-decoration: none;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./assets/fonts/ProximaNova/ProximaNova-Light.woff)
    format("woff");
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./assets/fonts/ProximaNova/ProximaNova-Regular.woff)
    format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./assets/fonts/ProximaNova/ProximaNova-Semibold.woff)
    format("woff");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./assets/fonts/ProximaNova/ProximaNova-Bold.woff)
    format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Signifier;
  src: url(./assets/fonts/signifier/signifier-extralight.woff2)
    format("woff2");
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

@media (max-width: 1700px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

body {
  color: #07393C;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.63;
  margin: 0;
}

.block-title + .title-h2,
.title-h1 {
  font-family: Signifier;
}

.title,
strong {
  font-weight: 600;
}

body a {
  color: inherit;
  display: inline;
  padding: 0;
}

body video {
  max-width: initial;
}

.preload * {
  transition: none !important;
}

a {
  transition: text-decoration-color 0.3s;
}

a:hover {
  text-decoration-color: transparent;
}

.no-js .sr {
  opacity: 1 !important;
}

.title {
  color: #07393C;
  font-size: 3.75rem;
  line-height: 1.17;
  margin: 0 0 1.5rem;
}

@media (max-width: 1440px), (max-height: 768px) {
  .title {
    font-size: 3.571rem;
  }
}

@media (max-width: 640px) {
  .title {
    font-size: 2.5rem;
  }
}

.title-h1 {
  font-size: 32px;
  line-height: 1.25;
  font-weight: 300;
  margin: 0;
}

@media (min-width: 1280px) {
  .title-h1 {
    font-size: 56px;
    line-height: 1;
  }
}

@media (min-width: 1680px) {
  .title-h1 {
    font-size: 64px;
  }
}

@media (min-width: 1920px) {
  .title-h1 {
    font-size: 72px;
  }
}

@media (min-width: 2560px) {
  .title-h1 {
    font-size: 88px;
  }
}

.title-h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
  margin: 0 0 32px;
}

@media (min-width: 768px) {
  .title-h2 {
    margin-bottom: 56px;
  }
}

@media (min-width: 1280px) {
  .title-h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 64px;
  }
}

@media (min-width: 1680px) {
  .title-h2 {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (min-width: 1920px) {
  .title-h2 {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 80px;
  }
}

@media (min-width: 2560px) {
  .title-h2 {
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 120px;
  }
}

.title-h4 {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
}

@media (min-width: 1280px) {
  .title-h4 {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (min-width: 1680px) {
  .title-h4 {
    font-size: 28px;
    line-height: 36px;
  }
}

@media (min-width: 1920px) {
  .title-h4 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (min-width: 2560px) {
  .title-h4 {
    font-size: 40px;
    line-height: 48px;
  }
}

.header__menu--new .menu-title,
.title-h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

@media (min-width: 1280px) {
  .header__menu--new .menu-title,
  .title-h5 {
    line-height: 24px;
  }
}

@media (min-width: 1920px) {
  .header__menu--new .menu-title,
  .title-h5 {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (min-width: 2560px) {
  .header__menu--new .menu-title,
  .title-h5 {
    font-size: 24px;
    line-height: 32px;
  }
}

.block-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin: 0 0 24px;
}

@media (min-width: 768px) {
  .block-title {
    margin-bottom: 32px;
  }
}

@media (min-width: 1920px) {
  .block-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 48px;
  }
}

@media (min-width: 2560px) {
  .block-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 56px;
  }
}

.description {
  color: #373737;
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 0 auto 3rem;
  max-width: 800px;
}

@media (max-width: 1440px), (max-height: 768px) {
  .description {
    font-size: 1.143rem;
  }
}

.description a {
  color: #2C666E;
  font-weight: 600;
}

.description-n {
  font-size: 14px;
  line-height: 1.43;
  font-weight: 400;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyButtonDecline,
.header__menu > li .block-group .dropdown-item {
  text-align: left;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

img[src$=".svg"] {
  height: auto;
  width: auto;
}

img {
  -o-object-fit: contain;
  object-fit: contain;
  vertical-align: top;
}

.pl-mid {
  padding-left: 16px;
}

@media (min-width: 768px) {
  .pl-mid {
    padding-left: 64px;
  }
}

@media (min-width: 960px) {
  .pl-mid {
    padding-left: 80px;
  }
}

@media (min-width: 1280px) {
  .description-n {
    font-size: 15px;
    line-height: 1.6;
  }

  .pl-mid {
    padding-left: 104px;
  }
}

@media (min-width: 1440px) {
  .pl-mid {
    padding-left: 112px;
  }
}

@media (min-width: 1920px) {
  .description-n {
    font-size: 16px;
    line-height: 1.5;
  }

  .pl-mid {
    padding-left: 152px;
  }
}

@media (min-width: 2560px) {
  .description-n {
    font-size: 20px;
    line-height: 1.6;
  }

  .pl-mid {
    padding-left: 198px;
  }
}

.pl-big {
  padding-left: 16px;
}

@media (min-width: 768px) {
  .pl-big {
    padding-left: 64px;
  }
}

@media (min-width: 960px) {
  .pl-big {
    padding-left: 80px;
  }
}

@media (min-width: 1280px) {
  .pl-big {
    padding-left: 208px;
  }
}

@media (min-width: 1440px) {
  .pl-big {
    padding-left: 224px;
  }
}

.link {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
}

.slick-dots li button:after {
  top: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
}

.link-n {
  align-items: center;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  color: inherit;
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  gap: 8px;
  line-height: 1.43;
  margin-left: -7px;
  margin-right: -7px;
  position: relative;
}

.link-n:after,
.link-n:before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L9 1M9 1H1M9 1V9' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  flex: 0 0 10px;
  height: 10px;
  transition: transform 0.3s;
  width: 10px;
}

.link-n:before {
  margin-right: -18px;
  transform: scale(0);
}

.link-n__text {
  transition: transform 0.3s;
}

.link-n:focus-visible:before,
.link-n:hover:before {
  transform: scale(1);
}

.link-n:focus-visible:after,
.link-n:hover:after,
.skip-the-section:focus-visible:before {
  transform: scale(0);
}

.link-n:focus-visible .link-n__text,
.link-n:hover .link-n__text {
  transform: translateX(18px);
}

.link-n:focus-visible {
  border-radius: 20px;
  outline-offset: 4px;
  outline: #2C666E solid 2px;
}

.link-n--white {
  color: #fff;
}

.link-n--white:after,
.link-n--white:before {
  filter: brightness(0) invert(1);
}

.section--black {
  background-color: #07393C;
}

.section-n {
  padding-top: 64px;
  padding-bottom: 64px;
}

@media (min-width: 768px) {
  .section-n {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1280px) {
  .link-n {
    font-size: 15px;
    line-height: 1.6;
  }

  .section-n {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (min-width: 1920px) {
  .pl-big {
    padding-left: 304px;
  }

  .link-n {
    font-size: 16px;
    line-height: 1.5;
  }

  .section-n {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}

@media (min-width: 2560px) {
  .pl-big {
    padding-left: 400px;
  }

  .link-n {
    font-size: 20px;
    line-height: 1.6;
  }

  .link-n:after,
  .link-n:before {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6569 13.6566L13.6569 2.34291M2.34314 2.34292L13.6569 2.34291M13.6569 2.34291L2.34314 13.6566' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
    flex-basis: 16px;
    height: 16px;
    width: 16px;
  }

  .link-n:before {
    margin-right: -24px;
  }

  .link-n:focus-visible .link-n__text,
  .link-n:hover .link-n__text {
    transform: translateX(24px);
  }

  .section-n {
    padding-top: 240px;
    padding-bottom: 240px;
  }
}

.section-n.pb0 {
  padding-bottom: 0;
}

[id^="pl-"]::before {
  content: "";
  display: block;
  height: 72px;
  margin-top: -72px;
  visibility: hidden;
}

@media (min-width: 768px) {
  [id^="pl-"]::before {
    height: 80px;
    margin-top: -80px;
  }
}

@media (min-width: 1280px) {
  [id^="pl-"]::before {
    height: 86px;
    margin-top: -86px;
  }
}

.skip-the-section {
  align-self: flex-start;
  margin-top: -40px;
  opacity: 0;
  position: absolute;
  transition: opacity 0.1s cubic-bezier(0.4, 0.2, 0.4, 1);
  z-index: -1;
}

.btn-n {
  cursor: pointer;
  transition-duration: 0.3s;
  text-decoration: none;
  -webkit-appearance: none;
  text-align: center;
}

.skip-the-section:after,
.skip-the-section:before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L5 8M9 4L5 8M5 8L5 1.74846e-07' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
}

.skip-the-section:focus-visible {
  opacity: 1;
  z-index: inherit;
}

.skip-the-section:focus-visible:after {
  transform: scale(1);
}

.skip-the-section:focus-visible .link-n__text {
  transform: translateX(0);
}

.container-n,
.search--new {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

@media (min-width: 768px) {
  .container-n,
  .search--new {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: 1280px) {
  .skip-the-section {
    margin-top: -56px;
  }

  .container-n,
  .search--new {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1440px) {
  [id^="pl-"]::before {
    height: 112px;
    margin-top: -112px;
  }

  .container-n,
  .search--new {
    padding-left: 56px;
    padding-right: 56px;
  }
}

@media (min-width: 2560px) {
  [id^="pl-"]::before {
    height: 136px;
    margin-top: -136px;
  }

  .skip-the-section {
    margin-top: -72px;
  }

  .container-n,
  .search--new {
    padding-left: 88px;
    padding-right: 88px;
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
  }
}

.row {
  display: flex;
}

.btn-n--black {
  background-color: #07393C;
  color: #fff;
}

[dir="rtl"] .btn-n:before {
  margin-left: -18px;
  margin-right: 0;
}

[dir="rtl"] .btn-n:focus-visible .btn-n__text,
[dir="rtl"] .btn-n:hover .btn-n__text {
  transform: translateX(-18px);
}

.btn-n {
  align-items: center;
  border-radius: 48px;
  border: none;
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  gap: 8px;
  line-height: 16px;
  outline: 0;
  padding: 12px 16px;
  transition-property: background-color, color;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
}

@media (min-width: 1280px) {
  .btn-n {
    font-size: 15px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1920px) {
  .btn-n {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 24px;
  }
}

.btn-n:after,
.btn-n:before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L9 1M9 1H1M9 1V9' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  flex: 0 0 10px;
  height: 10px;
  transition: transform 0.3s;
  width: 10px;
}

@media (min-width: 2560px) {
  [dir="rtl"] .btn-n:before {
    margin-left: -24px;
    margin-right: 0;
  }

  [dir="rtl"] .btn-n:focus-visible .btn-n__text,
  [dir="rtl"] .btn-n:hover .btn-n__text {
    transform: translateX(-24px);
  }

  .btn-n {
    font-size: 20px;
    line-height: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .btn-n:after,
  .btn-n:before {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6569 13.6566L13.6569 2.34291M2.34314 2.34292L13.6569 2.34291M13.6569 2.34291L2.34314 13.6566' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
    flex-basis: 16px;
    height: 16px;
    width: 16px;
  }
}

.btn-n:before {
  margin-right: -18px;
  transform: scale(0);
}

.btn-n__text {
  transition: transform 0.3s;
}

.btn-n:focus-visible:before,
.btn-n:hover:before {
  transform: scale(1);
}

.btn-n:focus-visible:after,
.btn-n:hover:after {
  transform: scale(0);
}

.btn-n:focus-visible .btn-n__text,
.btn-n:hover .btn-n__text {
  transform: translateX(18px);
}

.btn-n:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.btn-n--black:after,
.btn-n--black:before {
  filter: brightness(0) invert(1);
}

.btn-n--black:focus-visible,
.btn-n--black:hover {
  background-color: #2C666E;
}

.btn-n--gray {
  background-color: #e9edf3;
  color: #07393C;
}

.btn-n--gray:focus-visible,
.btn-n--gray:hover {
  background-color: #c7c7d6;
}

.btn-n--white {
  background-color: #fff;
  color: #07393C;
}

.btn-n--white:focus-visible,
.btn-n--white:hover {
  background-color: #2C666E;
  color: #fff;
}

.btn-n--white:focus-visible:after,
.btn-n--white:focus-visible:before,
.btn-n--white:hover:after,
.btn-n--white:hover:before {
  filter: brightness(0) invert(1);
}

.btn-n--small {
  padding: 8px 12px;
}

@media (min-width: 1920px) {
  .btn-n--small {
    padding: 8px 16px;
  }
}

@media (min-width: 2560px) {
  .btn-n:before {
    margin-right: -24px;
  }

  .btn-n:focus-visible .btn-n__text,
  .btn-n:hover .btn-n__text {
    transform: translateX(24px);
  }

  .btn-n--small {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.form .gform_wrapper,
.form ul {
  margin: 0;
}

.form ul {
  padding: 0;
  list-style: none;
}

.form__tooltip {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  background-color: #fff;
  border-radius: 5px;
  bottom: 0;
  box-shadow: 0 25px 70px 0 rgba(29, 55, 99, 0.15);
  color: #4a4a4a;
  display: none;
  font-size: 0.875rem;
  left: 100%;
  line-height: 1.64;
  margin-left: 10px;
  padding: 20px;
  position: absolute;
  width: 350px;
  z-index: 2;
}

.form__tooltip:after {
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  border-top: 10px solid transparent;
  bottom: 15px;
  content: "";
  position: absolute;
  right: 100%;
}

@media (max-width: 1000px) {
  .form__tooltip {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    bottom: 100%;
    left: 0;
    margin: 0 0 20px;
    right: 0;
    width: auto;
  }

  .form__tooltip:after {
    border-bottom: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    right: auto;
    left: 20px;
    top: 100%;
  }
}

.form__info {
  color: #c7c7d6;
  font-size: 12px;
  grid-column: span 2;
  line-height: 16px;
  margin: -8px 0 0;
  order: 9;
}

.form__info a {
  color: #fff;
  text-decoration: underline;
}

.form
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]),
.form textarea {
  background: 0 0;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  font-family: inherit;
  font-size: 16px;
  line-height: 25px;
  outline: 0;
  padding: 23px 16px 7px;
  transition: border-bottom-color 0.3s;
  vertical-align: top;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  width: 100%;
}

.form
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]):-webkit-autofill,
.form textarea:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0 1000px #07393C inset !important;
}

.form
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]):-webkit-autofill:focus,
.form
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]):-webkit-autofill:hover,
.form textarea:-webkit-autofill:focus,
.form textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0 1000px #07393C inset !important;
}

.form
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"])::-moz-placeholder,
.form textarea::-moz-placeholder {
  color: #8a8a8a;
}

.form
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"])::placeholder,
.form textarea::placeholder {
  color: #8a8a8a;
}

.form .formrowlist {
  display: grid !important;
  grid-gap: 24px 16px;
  grid-template-columns: 1fr 1fr;
}

.form .formrow {
  position: relative;
}

.form .formrow .gfield {
  margin-top: 0;
}

.form .formrow .gfield_label {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  left: 16px;
  line-height: 24px;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  transition-duration: 0.3s;
  transition-property: transform, opacity, font-size;
  white-space: nowrap;
}

.form .formrow__name {
  order: 1;
}

.form .formrow__phone {
  order: 3;
}

.form .formrow__company {
  grid-column: span 2;
  order: 5;
}

@media (max-width: 480px) {
  .form__info {
    grid-column: span 1;
  }

  .form .formrowlist {
    grid-template-columns: 1fr;
  }

  .form .formrow__company {
    grid-column: span 1;
  }
}

.form .formrow__email {
  order: 2;
}

.form .formrow__country {
  order: 4;
}

.form .formrow__message {
  grid-column: span 2;
  order: 6;
  position: relative;
}

@media (max-width: 480px) {
  .form .formrow__message {
    grid-column: span 1;
  }
}

.form .formrow__message textarea {
  overflow: hidden;
  padding-right: 50px;
}

.form .formrow__attach {
  grid-column: span 2;
  order: 7;
}

@media (max-width: 480px) {
  .form .formrow__attach {
    grid-column: span 1;
  }
}

.form .formrow__attach .ginput_preview {
  background-color: #f4f5f7;
  display: flex;
  margin-bottom: 24px;
  padding: 10px 16px 10px 38px;
  position: relative;
}

.form .formrow__attach .ginput_preview img {
  cursor: pointer;
  flex: 0 0 15px;
  margin-left: -22px;
  margin-right: 8px;
  opacity: 0;
  position: relative;
  z-index: 1;
}

.form .formrow__attach .ginput_preview strong {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  word-break: break-all;
}

.form .formrow--checkbox .gfield_checkbox input:checked ~ label {
  font-weight: 400 !important;
}

.form .formrow__attach .ginput_preview strong:after,
.form .formrow__attach .ginput_preview strong:before {
  background: #000;
  content: "";
  height: 1px;
  left: -21px;
  opacity: 0.5;
  position: absolute;
  top: 11px;
  width: 12px;
}

.form .formrow__attach .ginput_preview strong:after {
  transform: rotate(-45deg);
}

.form .formrow__attach .ginput_preview strong:before {
  transform: rotate(45deg);
}

.form .formrow--checkbox {
  grid-column: span 2;
  margin-top: -24px;
  order: 8;
}

.form .formrow--checkbox .gfield_checkbox input {
  position: absolute;
  opacity: 0;
}

.form .formrow--checkbox .gfield_checkbox input:focus-visible ~ label:after {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.form .formrow--checkbox .gfield_checkbox input:checked ~ label:before {
  opacity: 1;
}

.form .formrow--checkbox .gfield_checkbox input:checked ~ label:after {
  border-color: rgba(255, 255, 255, 0.8);
}

.form .formrow--checkbox .gfield_checkbox label {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  max-width: 100%;
  min-height: 20px;
  padding-left: 48px;
  padding-top: 1px;
  position: relative;
}

.form .formrow--checkbox .gfield_checkbox label:after,
.form .formrow--checkbox .gfield_checkbox label:before {
  content: "";
  position: absolute;
  transition-duration: 0.3s;
}

.form .formrow--checkbox .gfield_checkbox label:before {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  height: 11px;
  left: 23px;
  opacity: 0;
  top: 3px;
  transform: rotate(45deg);
  transition-property: opacity;
  width: 6px;
}

@media (min-width: 1280px) {
  .form__info {
    font-size: 14px;
    line-height: 24px;
  }

  .form .formrowlist {
    grid-gap: 24px 40px;
  }

  .form .formrow--checkbox .gfield_checkbox label {
    font-size: 14px;
    line-height: 24px;
    padding-top: 0;
  }

  .form .formrow--checkbox .gfield_checkbox label:before {
    top: 4px;
  }
}

.form .formrow--checkbox .gfield_checkbox label:after {
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  height: 20px;
  left: 16px;
  top: 0;
  transition-property: border-color;
  width: 20px;
}

.form .formrow--checkbox .gfield_checkbox li:hover label:after {
  border-color: rgba(255, 255, 255, 0.6);
}

.form
  .formrow__infocus
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]),
.form .formrow__infocus textarea {
  border-bottom-color: #fff;
}

.form .formrow__infocus .form__tooltip {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  display: block;
}

.form .formrow--changed .gfield_label,
.form .formrow__infocus .gfield_label {
  font-size: 12px;
  line-height: 16px;
  opacity: 0.4;
  transform: translateY(-7px);
}

.form .gfield_label {
  color: rgba(29, 55, 99, 0.5);
  left: 30px;
}

.form .gform_wrapper .field_description_below .gfield_description {
  color: #939393;
  font-size: 12px;
  line-height: 16px;
  padding-right: 0;
  padding-top: 5px;
}

.form .gform_wrapper .field_description_below .validation_message {
  bottom: -18px;
  color: #ff4925;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  left: 16px;
  max-width: 100%;
  overflow: hidden;
  padding-top: 5px;
  position: absolute;
  right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form .gform_wrapper textarea.medium {
  height: 56px;
  min-height: 56px;
  resize: vertical;
}

.form .gform_wrapper .gfield_required {
  display: inline-block;
  color: #fff;
  margin-left: 2px;
  opacity: 0.7;
}

.form .gform_wrapper .charleft,
.form .gform_wrapper .gform_validation_errors {
  display: none;
}

.form
  .gform_wrapper.gform_validation_error
  .gform_body
  ul
  li.gfield.gfield_error {
  background-color: transparent;
  border: none;
  margin-bottom: 0 !important;
  max-width: 100% !important;
  padding: 0;
}

.form
  .gform_wrapper.gform_validation_error
  .gform_body
  ul
  li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
  max-width: 100% !important;
}

.form
  .gform_wrapper.gform_validation_error
  .gform_body
  ul
  li.gfield.gfield_error
  .gfield_label {
  color: #ff4925 !important;
  margin-top: 0;
}

.form
  .gform_wrapper.gform_validation_error
  .gform_body
  ul
  li.gfield.gfield_error
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]),
.form
  .gform_wrapper.gform_validation_error
  .gform_body
  ul
  li.gfield.gfield_error
  textarea {
  border: none !important;
  border-bottom: 1px solid #ff4925 !important;
  color: #ff4925 !important;
  margin-bottom: 0;
}

.form
  .gform_wrapper.gform_validation_error
  .gform_body
  ul
  li.gfield.gfield_error
  .ginput_container {
  margin-top: 0;
}

.form
  .gform_wrapper.gform_validation_error
  .gform_body
  ul
  li.gfield.gfield_error
  .validation_message {
  bottom: -18px;
  color: #ff4925;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  padding-top: 5px !important;
  position: absolute;
}

.form
  .gform_wrapper.gform_validation_error
  .gform_body
  ul
  li.gfield.gfield_error
  .gfield_required {
  color: #ff4925;
}

.form
  .gform_wrapper.gform_validation_error
  .gform_body
  .formrow--changed
  ul
  li.gfield.gfield_error
  .ginput_container
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]),
.form
  .gform_wrapper.gform_validation_error
  .gform_body
  .formrow--changed
  ul
  li.gfield.gfield_error
  .ginput_container
  textarea,
.form
  .gform_wrapper.gform_validation_error
  .gform_body
  .formrow__infocus
  ul
  li.gfield.gfield_error
  .ginput_container
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]),
.form
  .gform_wrapper.gform_validation_error
  .gform_body
  .formrow__infocus
  ul
  li.gfield.gfield_error
  .ginput_container
  textarea {
  border-bottom: 1px solid #fff !important;
  color: #fff !important;
}

.form
  .gform_wrapper.gform_validation_error
  .gform_body
  .formrow__infocus
  ul
  li.gfield.gfield_error
  .gfield_label {
  color: #fff !important;
}

.form .gform_wrapper .ginput_container_checkbox {
  margin-top: 0 !important;
}

.form .gform_wrapper .gform_footer {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  margin-top: 40px;
  padding: 0;
  position: relative;
}

@media (max-width: 1440px) {
  .form .gform_wrapper .gform_footer {
    margin-top: 32px;
  }
}

@media (max-width: 1366px) {
  .form .gform_wrapper .gform_footer {
    margin-top: 24px;
  }
}

.form .gform_wrapper .gform_footer input[type="submit"] {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L9 1M9 1H1M9 1V9' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 16px) center;
  background-repeat: no-repeat;
  background-size: 10px;
  border-radius: 48px;
  border: none;
  color: #07393C;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
  outline: 0;
  padding: 12px 32px 12px 16px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.3s;
  transition-property: background-color, background-image, color;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-appearance: none;
}

.form .gform_wrapper .gform_footer input[type="submit"]:focus,
.form .gform_wrapper .gform_footer input[type="submit"]:hover {
  text-decoration: none;
}

.form .gform_wrapper .gform_footer input[type="submit"]:disabled {
  cursor: disabled;
  opacity: 0.65;
}

.form .gform_wrapper .gform_footer input[type="submit"]:hover {
  background-color: #2C666E;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L9 1M9 1H1M9 1V9' stroke='%23ffffff'/%3E%3C/svg%3E%0A");
  color: #fff;
}

.form .gform_wrapper .gform_footer input[type="submit"]:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.form .atachfile {
  display: flex;
  flex-direction: column;
}

.form .atachfile > ul {
  order: 1;
}

.form .atachfile .atachfile_cont {
  border-radius: 50%;
  order: 0;
  position: absolute;
  right: 0;
  top: -72px;
}

@media (min-width: 1440px) {
  .form .formrowlist {
    grid-gap: 32px 40px;
  }

  .form .atachfile .atachfile_cont {
    top: -80px;
  }
}

.form .atachfile .atachfile_link {
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  height: 0;
  margin: 0;
  overflow: hidden;
  padding-top: 40px;
  position: relative;
  width: 40px;
}

.form .atachfile .atachfile_link:after {
  border: 1px solid #2c2a39;
  background-color: #2c2a39;
  border-radius: 50%;
  content: "";
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  transition-property: background-color, border-color;
  width: 40px;
}

.form .atachfile .atachfile_link:before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 7.5L8.5 2.5C9.60457 1.39543 11.3954 1.39543 12.5 2.5V2.5C13.6046 3.60457 13.6046 5.39543 12.5 6.5L4.5 14.5C3.94772 15.0523 3.05228 15.0523 2.5 14.5V14.5C1.94772 13.9477 1.94772 13.0523 2.5 12.5L10 5' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  filter: brightness(0) invert(1);
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
  z-index: 1;
}

.form .atachfile .atachfile_link:hover:after {
  background-color: transparent;
  border-color: #54575e;
}

.form .atachfile .atachfile_filename {
  background-color: #f4f5f7;
  color: rgba(29, 55, 99, 0.5);
  position: static;
  margin-bottom: 0;
  margin-top: 0;
  word-break: break-word;
}

.form .atachfile .atachfile_filename:before {
  content: "";
}

.form .atachfile .atachfile_filename--correct {
  font-size: 14px;
  line-height: 24px;
  padding: 10px 16px 10px 38px;
  position: relative;
}

.form .atachfile .atachfile_filename--correct:after,
.form .atachfile .atachfile_filename--correct:before {
  background: #000;
  content: "";
  height: 1px;
  left: 17px;
  opacity: 0.5;
  position: absolute;
  top: 21px;
  width: 12px;
}

.form .atachfile .atachfile_filename--correct:after {
  transform: rotate(-45deg);
}

.form .atachfile .atachfile_filename--correct:before {
  transform: rotate(45deg);
}

.form .atachfile .atachfile_filename--correct + .atachfile_remove {
  display: block;
}

.form .atachfile .atachfile_filename--error {
  background-color: transparent;
  color: #ff4925;
  font-size: 12px;
  line-height: 16px;
  margin-top: 0;
  padding-left: 16px;
}

.form .atachfile .atachfile_filename:not(:empty) {
  margin-bottom: 24px;
}

.form .atachfile .atachfile_text {
  display: none;
}

.form .atachfile .gfield_label {
  height: 0;
  opacity: 0;
  overflow: hidden;
  width: 0;
}

.form .atachfile .gform_fileupload_rules,
.form .atachfile .screen-reader-text {
  display: none !important;
}

.form .atachfile .atachfile_remove {
  cursor: pointer;
  display: none;
  height: 15px;
  left: 16px;
  opacity: 0.3;
  position: absolute;
  top: 14px;
  width: 15px;
}

.form .atachfile input[type="file"] {
  cursor: pointer;
  height: 1px;
  right: 0;
  opacity: 0;
  padding: 0 !important;
  position: absolute;
  top: -72px;
  width: 1px;
}

.form .atachfile input[type="file"].osx {
  height: 40px;
  width: 40px;
  z-index: 2;
}

.form .atachfile:has(input[type="file"]:focus-visible) .atachfile_link:after {
  background-color: transparent;
  border-color: #54575e;
}

.form .atachfile:has(input[type="file"]:focus-visible) .atachfile_cont {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.form .gform_delete + strong {
  color: rgba(29, 55, 99, 0.5);
}

.form .gform_delete + strong::after,
.form .gform_delete + strong::before {
  background-color: rgba(29, 55, 99, 0.5);
}

.form .gform-loader {
  -webkit-animation: 1.1s ease-in-out infinite load;
  animation: 1.1s ease-in-out infinite load;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle opacity='0.24' cx='12' cy='12' r='11' stroke='%23C7C7D6' stroke-width='2'/%3E%3Cmask id='mask0_25429_72449' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Ccircle cx='12' cy='12' r='11' stroke='%239B9FAD' stroke-width='2'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_25429_72449)'%3E%3Crect x='-1' y='12' width='13' height='13' fill='%239B9FAD'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 24px;
  height: 24px;
  width: 24px;
}

.form #gform_ajax_spinner_1,
.form #gform_ajax_spinner_12,
.form #gform_ajax_spinner_13,
.form #gform_ajax_spinner_15,
.form #gform_ajax_spinner_16,
.form #gform_ajax_spinner_17,
.form #gform_ajax_spinner_18,
.form #gform_ajax_spinner_2,
.form #gform_ajax_spinner_23,
.form #gform_ajax_spinner_24,
.form #gform_ajax_spinner_3,
.form #gform_ajax_spinner_4,
.form #gform_ajax_spinner_6,
.form #gform_ajax_spinner_7,
.form #gform_ajax_spinner_9,
.form .gform_ajax_spinner {
  flex: 0 0 20px;
  height: 20px;
  width: 20px;
}

.form #gform_ajax_spinner_5 {
  bottom: -25px;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  padding-left: 0;
  height: 20px;
  width: 20px;
}

.form .gform_legacy_markup_wrapper .gform_hidden,
.form .gform_legacy_markup_wrapper input.gform_hidden,
.form .gform_legacy_markup_wrapper input[type="hidden"] {
  display: none !important;
  max-height: 1px !important;
  overflow: hidden;
}

.form
  .gform_legacy_markup_wrapper
  .gform_body
  ul.gform_fields
  li.gfield.gform_validation_container,
.form .gform_legacy_markup_wrapper .gform_validation_container,
.form .gform_legacy_markup_wrapper li.gform_validation_container,
.form
  .gform_legacy_markup_wrapper
  ul.gform_fields
  li.gfield.gform_validation_container,
.form .gform_validation_container {
  display: none !important;
  position: absolute !important;
  left: -9000px;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.autocomplete-items {
  position: absolute;
  top: 100%;
  z-index: 1;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.autocomplete-items {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  left: 0;
  max-height: 300px;
  overflow: auto;
  right: 0;
}

.header__menu > li > .dropdown-menu__wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.autocomplete-items div {
  border-radius: 8px;
  font-size: 1rem;
  margin: 0 10px;
  padding: 7px 16px;
  cursor: pointer;
  color: #07393C;
}

.autocomplete-items div:first-child {
  margin-top: 10px;
}

.autocomplete-items div:last-child {
  margin-bottom: 10px;
}

.autocomplete-active,
.autocomplete-items div:hover {
  background-color: #f9f9f9;
}

.search__field {
  background: 0 0;
  border: none;
  border-bottom: 1px solid rgba(30, 29, 40, 0.15);
  color: #373737;
  font-size: 1.25rem;
  outline: 0;
  padding: 12px 0 12px 40px;
  width: 540px;
}

@media (max-width: 1440px) {
  .search__field {
    font-size: 1.143rem;
    padding: 7px 0 7px 35px;
    width: 300px;
  }
}

.search__field:-webkit-autofill {
  -webkit-text-fill-color: #373737 !important;
  -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset !important;
  border-bottom-color: #dddddf;
}

.search__field:-webkit-autofill:focus,
.search__field:-webkit-autofill:hover {
  -webkit-text-fill-color: #373737 !important;
  -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset !important;
  border-bottom-color: #dddddf;
}

.dots--white .slick-dots li button:before,
.search--new {
  background-color: #fff;
}

.search__filter .selectric-wrapper {
  width: 420px;
}

@media (max-width: 960px) {
  .title br {
    display: none;
  }

  .search__field {
    font-size: 1rem;
    padding-left: 25px;
    width: 100%;
  }

  .search__filter .selectric-wrapper {
    width: 350px;
  }

  .search--new {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 767px) {
  .title-h2 br {
    display: none;
  }

  .search__filter .selectric-wrapper {
    width: 100%;
  }

  .contact-us-new .section__row {
    padding-left: 0;
  }
}

.search__filter .selectric-wrapper .selectric .label {
  font-size: 1.25rem;
  height: 50px;
  line-height: 50px;
}

.search--new {
  border-radius: 8px 8px 0 0;
  bottom: 0;
  display: none;
  left: 0;
  padding-bottom: 52px;
  padding-top: 80px;
  position: fixed;
  right: 0;
  z-index: 1;
}

.search--new__icon {
  display: block;
  height: 16px;
  vertical-align: top;
  width: 16px;
}

.search--new__icon-stroke {
  stroke: #fff;
}

@media (min-width: 961px) {
  .search--new {
    border-radius: 0 0 8px 8px;
    bottom: auto;
    margin-top: -1px;
    position: absolute;
    top: 100%;
  }

  .search--new__icon-stroke {
    stroke: #07393C;
  }
}

.search--new__icon-wrap {
  background-color: #2c2a39;
  border-radius: 40px;
  border: 1px solid transparent;
  cursor: pointer;
  margin-left: 8px;
  padding: 11px 19px;
  transition-duration: 0.3s;
  transition-property: background-color, border-color;
}

.search--new__icon-wrap:focus-visible,
.search--new__icon-wrap:hover {
  background-color: transparent;
  border-color: #c7c7d6;
}

.search--new__icon-wrap:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.search--new__icon-wrap.active:after {
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  cursor: default;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.search--new__icon-wrap.active .search--new__icon {
  opacity: 0;
}

.search--new__icon-wrap.active ~ .search--new {
  display: block;
}

@media (min-width: 961px) {
  .search--new__icon-wrap {
    background-color: #e9edf3;
  }

  .search--new__icon-wrap.active,
  .search--new__icon-wrap:focus-visible,
  .search--new__icon-wrap:hover {
    background-color: #c7c7d6;
  }

  .search--new__icon-wrap.active:after {
    top: 100%;
  }

  .search--new__icon-wrap.active ~ .search--new .search--new__close {
    top: -51px;
  }
}

@media (min-width: 1280px) {
  .form .formrow--checkbox .gfield_checkbox label:after {
    top: 1px;
  }

  .form .gform_wrapper .gform_footer input[type="submit"] {
    background-position: calc(100% - 20px) center;
    font-size: 15px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 36px;
  }

  .search--new__icon-wrap {
    padding: 15px 24px;
  }

  .search--new__icon-wrap.active ~ .search--new .search--new__close {
    right: 36px;
    top: -55px;
  }
}

@media (min-width: 1440px) {
  .form .atachfile input[type="file"] {
    top: -80px;
  }

  .search--new__icon-wrap.active ~ .search--new .search--new__close {
    right: 69px;
    top: -63px;
  }
}

@media (min-width: 1920px) {
  .form .gform_wrapper .gform_footer input[type="submit"] {
    background-position: calc(100% - 24px) center;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 40px 16px 24px;
  }

  .search--new__icon-wrap {
    padding: 19px 27px;
  }

  .search--new__icon-wrap.active ~ .search--new .search--new__close {
    right: 72px;
  }
}

@media (min-width: 2560px) {
  .form .gform_wrapper .gform_footer input[type="submit"] {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6569 13.6566L13.6569 2.34291M2.34314 2.34292L13.6569 2.34291M13.6569 2.34291L2.34314 13.6566' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
    background-position: calc(100% - 32px) center;
    background-size: 16px;
    font-size: 20px;
    line-height: 32px;
    padding-left: 32px;
    padding-right: 56px;
  }

  .search--new__icon-wrap {
    padding: 23px 31px;
  }

  .search--new__icon-wrap.active ~ .search--new .search--new__close {
    right: 108px;
    top: -67px;
  }
}

.search--new__close {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}

.search--new__form {
  align-items: flex-end;
  display: flex;
  gap: 8px;
  position: relative;
}

.search--new__label {
  color: #07393C;
  font-size: 12px;
  left: 16px;
  line-height: 16px;
  opacity: 0.4;
  position: absolute;
  top: -10px;
}

.search--new__field {
  background: 0 0;
  border: none;
  border-bottom: 1px solid #c7c7d6;
  color: #07393C;
  flex: 1;
  font-size: 16px;
  line-height: 24px;
  outline: 0;
  padding: 0 16px 8px;
}

.search--new__field:-webkit-autofill {
  -webkit-text-fill-color: #07393C !important;
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

.search--new__field:-webkit-autofill:focus,
.search--new__field:-webkit-autofill:hover {
  -webkit-text-fill-color: #07393C !important;
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

@media (max-width: 960px) {
  .search--new__submit .btn-n__text {
    display: none;
  }

  .search--new__submit {
    background-color: #e9edf3;
    justify-content: center;
    width: 56px;
  }

  .search--new__submit:after,
  .search--new__submit:before {
    filter: brightness(1) invert(1);
  }
}

@media (min-width: 961px) {
  .search--new__submit {
    padding-left: 56px;
    padding-right: 56px;
  }
}

@media (min-width: 1280px) {
  .search--new__label {
    top: -4px;
  }

  .search--new__submit {
    padding-left: 65px;
    padding-right: 65px;
  }
}

@media (min-width: 1920px) {
  .search--new__label {
    top: 6px;
  }

  .search--new__submit {
    padding-left: 74px;
    padding-right: 74px;
  }
}

@media (min-width: 2560px) {
  .search--new__label {
    top: 12px;
  }

  .search--new__submit {
    padding-left: 90px;
    padding-right: 90px;
  }
}

.search--new__submit:after,
.search--new__submit:before {
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5009 15L11.2676 10.7667' stroke='white'/%3E%3Ccircle cx='7' cy='6.5' r='6' stroke='white'/%3E%3C/svg%3E%0A");
  flex-basis: 16px;
  height: 16px;
  width: 16px;
}

.search--new__submit:before {
  margin-right: -24px;
}

.search--new__submit:hover .btn-n__text {
  transform: translateX(24px);
}

.slick-slide {
  outline: 0;
}

.slick-next,
.slick-prev {
  background: 0 0;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 20px;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  background: 0 0;
  color: transparent;
  outline: 0;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
}

.slick-prev {
  left: -25px;
}

.slick-prev:before {
  content: "←";
}

.slick-next {
  right: -25px;
}

.slick-next:before {
  content: "→";
}

.popup__close:after,
.popup__close:before,
.slick-dots li button:after,
.slick-dots li button:before {
  content: "";
}

.slick-dots {
  bottom: -25px;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 20px;
}

.slick-dots li button {
  background: 0 0;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 22px;
  line-height: 0;
  outline: 0;
  padding: 5px;
  width: 22px;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 0.7;
}

.slick-dots li button:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  border-radius: 50%;
  height: 10px;
  left: 50%;
  opacity: 0.2;
  position: absolute;
  top: 50%;
  transform: translate(-5px, -5px);
  transition: opacity 0.3s;
  width: 10px;
}

.slick-dots li button:after {
  border: 1px solid;
  border-radius: 50%;
  height: 120%;
  left: 50%;
  position: absolute;
  width: 120%;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}

.slick-dots li.slick-active button:after {
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: transform 0.45s cubic-bezier(0.6, 0.01, 0, 1.38);
}

.dots--white .slick-dots li.slick-active button:after {
  border-color: #fff;
}

.dots--black .slick-dots li.slick-active button:after {
  border-color: #000;
}

.dots--black .slick-dots li button:before {
  background-color: #000;
}

.dots--top .slick-dots {
  bottom: auto;
  top: 0;
}

.video {
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
  text-decoration: none;
}

.popup,
.popup__bg {
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.video:focus-visible {
  outline: 0;
}

.popup {
  display: none;
  position: fixed;
  z-index: 999;
}

.popup.open {
  display: flex;
}

.popup.open .popup__bg {
  -webkit-animation: 0.3s fadeIn;
  animation: 0.3s fadeIn;
}

.popup__bg {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.popup__bg.darken {
  background-color: rgba(0, 0, 0, 0.8);
}

.popup__close {
  cursor: pointer;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 50px;
  z-index: 2;
}

.popup__close:after,
.popup__close:before {
  background-color: #fff;
  display: block;
  height: 2px;
  position: absolute;
  right: 9px;
  top: 23px;
  transition-property: background-color, opacity;
  transition-duration: 0.3s;
  width: 32px;
}

.popup__close:after {
  transform: rotate(45deg);
}

.popup__close:before {
  transform: rotate(-45deg);
}

.testimonial-new__author-name {
  font-weight: 700;
}

.popup__content {
  margin: auto;
  padding: 40px;
  position: relative;
  z-index: 1;
}

@media (max-width: 560px) {
  .popup__content {
    padding: 20px;
  }
}

.popup__content iframe {
  max-width: 100%;
  position: relative;
  z-index: 1;
}

@media (max-width: 960px) {
  .popup__content iframe {
    height: 490px;
  }
}

@media (max-width: 640px) {
  .popup__content iframe {
    height: 320px;
  }
}

@media (max-width: 420px) {
  .popup__content iframe {
    height: 220px;
  }
}

@media (max-height: 600px) {
  .popup__content iframe {
    height: 400px;
  }
}

@media (max-height: 450px) {
  .popup__content iframe {
    height: 300px;
  }
}

@media (max-height: 380px) {
  .popup__content iframe {
    height: 260px;
  }
}

.popup--download .popup__close:after,
.popup--download .popup__close:before {
  background-color: #d2d2d4;
  height: 3px;
}

.loader {
  height: 60px;
  left: 50%;
  width: 60px;
  top: 50%;
}

.popup--download .popup__close:hover:after,
.popup--download .popup__close:hover:before {
  background-color: #969697;
}

.loader {
  -webkit-animation: 1.1s ease-in-out infinite load;
  animation: 1.1s ease-in-out infinite load;
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid #fff;
  border-radius: 50%;
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  margin: -30px 0 0 -30px;
  position: absolute;
}

@-webkit-keyframes load {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.lang {
  position: relative;
}

.social {
  list-style: none;
  margin: 30px 0 0;
  padding: 0;
  display: flex;
}

.social__item {
  margin-right: 20px;
}

.social__link {
  align-items: center;
  background-color: #07393C;
  border-radius: 50%;
  display: flex;
  height: 48px;
  justify-content: center;
  outline: 0;
  transition: background-color 0.3s;
  width: 48px;
}

@media (max-width: 1440px) {
  .social__link {
    height: 40px;
    width: 40px;
  }
}

.social__link:hover {
  background-color: #2C666E;
}

.social__link:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.lang {
  width: 72px;
}

.no-js .lang:hover .lang__items {
  display: block;
  margin: 0;
}

.lang--new {
  color: #54575e;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  align-self: flex-end;
}

@media (min-width: 961px) {
  .lang--new {
    align-self: center;
    border-radius: 8px;
    border: 1px solid transparent;
    color: #07393C;
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
}

.lang--new__item.selected {
  color: #fff;
}

.lang--new__link {
  cursor: pointer;
  display: block;
  font-size: 15px;
  line-height: 24px;
  outline: 0;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

@media (min-width: 961px) {
  .lang--new__item.selected {
    color: #07393C;
  }

  .lang--new__link {
    padding: 7px 20px;
    position: relative;
  }
}

@media (min-width: 1280px) {
  .lang--new__link {
    padding: 11px 23px;
  }
}

@media (min-width: 1440px) {
  .lang--new__link {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .lang--new__link {
    padding: 15px 26px;
  }
}

.lang--new__link:hover {
  color: #fff;
}

@media (min-width: 961px) {
  .lang--new__link:hover {
    color: #2C666E;
  }

  .lang--new__link:before {
    border-radius: 20px;
    border: 2px solid #2C666E;
    content: "";
    height: 32px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.1s cubic-bezier(0.4, 0.2, 0.4, 1);
    width: 48px;
  }
}

.lang--new__link:focus-visible:before {
  opacity: 1;
}

@media (min-width: 2560px) {
  .lang--new__link {
    font-size: 20px;
    line-height: 32px;
    padding-top: 19px;
    padding-bottom: 19px;
  }

  .lang--new__link:before {
    height: 40px;
    width: 56px;
  }
}

.lang--new__items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

@media (min-width: 961px) {
  .lang--new__items {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    border: 1px solid #c7c7d6;
    border-top: 0;
    display: none;
    left: -1px;
    position: absolute;
    right: -1px;
    top: 100%;
    z-index: 1;
  }

  .lang--new.active {
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    border-color: #c7c7d6;
  }

  .lang--new.active .lang--new__items {
    display: block;
  }

  .lang--new.active .lang--new__items .lang--new__item {
    margin-top: -6px;
  }
}

.no-js .header--new__lang:hover .lang--new__items {
  display: block;
}

.testimonial-new:not(:first-child) {
  display: none;
}

.link-n:after,
.link-n:before {
  transition-duration: 0.2s;
  transition-property: filter, transform;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0.4, 1);
}

.testimonial__slider {
  margin-left: -10px;
  margin-right: -10px;
}

.testimonial__slider.slick-dotted.slick-slider {
  margin-bottom: 60px;
}

.testimonial__slider .slick-dots {
  bottom: -55px;
}

.testimonial__slider .slick-track {
  display: flex;
}

.testimonial__slider .slick-slide {
  height: auto;
}

.testimonial__slider.slick-initialized .testimonial {
  display: flex;
  flex-direction: column;
}

.testimonial-new__position,
.testimonial-new__text {
  color: #373737;
}

.testimonial-new {
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
}

.testimonial-new__title {
  margin-bottom: 24px;
}

.testimonial-new__slider {
  margin-left: -8px;
  margin-right: -8px;
  max-width: 100%;
}

.testimonial-new__text:before {
  content: url("data:image/svg+xml,%3Csvg width='24' height='16' viewBox='0 0 24 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.8664 0C23.4389 1.65442 22.9877 3.43945 22.5128 5.3551C22.0378 7.27075 21.6104 9.14286 21.2304 10.9714C20.8504 12.8 20.5417 14.4762 20.3043 16H13.5362L13.0375 15.2816C13.4649 13.7578 14.0111 12.1252 14.6761 10.3837C15.341 8.59864 16.0772 6.81361 16.8846 5.02857C17.692 3.24354 18.4757 1.56735 19.2356 0H23.8664ZM10.6864 0C10.259 1.65442 9.80778 3.43945 9.33283 5.3551C8.85788 7.27075 8.43042 9.14286 8.05046 10.9714C7.67049 12.8 7.36177 14.4762 7.1243 16H0.427458L0 15.2816C0.427458 13.7578 0.973654 12.1252 1.63859 10.3837C2.30352 8.59864 3.01595 6.81361 3.77588 5.02857C4.5833 3.24354 5.36697 1.56735 6.1269 0H10.6864Z' fill='%231E1D28'/%3E%3C/svg%3E%0A");
  display: block;
  margin-bottom: 24px;
  transform: scale(0.8);
  transform-origin: left center;
}

@media (min-width: 768px) {
  .testimonial-new__title {
    margin-bottom: 32px;
  }

  .testimonial-new__text:before {
    transform: none;
    margin-bottom: 32px;
  }
}

@media (min-width: 1440px) {
  .testimonial-new__title {
    margin-bottom: 40px;
  }

  .testimonial-new__text:before {
    margin-bottom: 40px;
  }
}

.testimonial-new__photo {
  display: block;
  flex: 0 0 80px;
  border-radius: 8px;
  width: 80px;
}

@media (min-width: 1920px) {
  .testimonial-new__photo {
    flex-basis: 128px;
    width: 128px;
  }
}

.testimonial-new__author {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

@media (min-width: 1280px) {
  .lang--new.active .lang--new__items .lang--new__item {
    margin-top: -13px;
  }

  .testimonial-new__author {
    margin-top: 40px;
  }

  .contact-us-new .container-n,
  .contact-us-new .search--new {
    padding-right: 16px;
  }
}

.testimonial-new__info {
  margin-top: auto;
}

.testimonial-new__timeline {
  background-color: rgba(84, 87, 94, 0.4);
  height: 1px;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
}

.testimonial-new__timeline-line {
  background-color: #07393C;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
}

.testimonial-new__timeline-line.animate {
  -webkit-animation: 10s linear autoplay-anim;
  animation: 10s linear autoplay-anim;
}

.testimonial-new__arrows {
  align-items: center;
  display: flex;
  gap: 16px;
}

.testimonial-new__arrow {
  height: 40px;
  transition: opacity 0.3s;
  vertical-align: top;
  width: auto;
}

.testimonial-new__arrow-circle {
  transition: fill 0.3s;
}

.testimonial-new__arrow-icon {
  transition: stroke 0.3s;
}

.testimonial-new__arrow.slick-disabled {
  opacity: 0.24;
}

.testimonial-new__arrow:not(.slick-disabled):hover {
  cursor: pointer;
}

.testimonial-new__arrow:not(.slick-disabled):hover
  .testimonial-new__arrow-circle {
  fill: #07393C;
}

.testimonial-new__arrow:not(.slick-disabled):hover
  .testimonial-new__arrow-icon {
  stroke: #fff;
}

@-webkit-keyframes autoplay-anim {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes autoplay-anim {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.contact-us {
  color: #fff;
  overflow: hidden;
}

@media (max-width: 960px) {
  .contact-us .row {
    flex-wrap: wrap;
  }
}

.contact-us .col-form {
  flex: 0 0 63%;
  position: relative;
  z-index: 1;
}

@media (max-width: 960px) {
  .contact-us .col-form {
    flex-basis: 100%;
  }
}

.contact-us .col-testimonial {
  flex: 0 0 32%;
  overflow: hidden;
}

@media (max-width: 960px) {
  .contact-us .col-testimonial {
    padding-bottom: 5px;
    padding-top: 30px;
    flex-basis: 100%;
  }
}

@media (max-width: 640px) {
  .contact-us .col-testimonial {
    display: none;
  }
}

.contact-us-new {
  overflow: hidden;
}

@media (min-width: 2600px) {
  .contact-us-new .container-n,
  .contact-us-new .search--new {
    padding-right: 88px;
  }
}

.contact-us-new .section__title {
  color: #fff;
  margin-bottom: 32px;
}

@media (min-width: 1440px) {
  .contact-us-new .section__title {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .contact-us-new .section__row {
    padding-right: 64px;
  }
}

@media (min-width: 960px) {
  .contact-us-new .section__row {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1.3fr 1fr;
    padding-right: 0;
  }
}

.contact-us-new .col-form {
  position: relative;
  z-index: 1;
}

.contact-us-new .col-testimonial {
  display: none;
}

@media (min-width: 960px) {
  .contact-us-new .col-testimonial {
    background-color: #f8f8f9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: -72px;
    margin-top: -72px;
    overflow: hidden;
    padding: 80px 24px 72px;
  }
}

@media (min-width: 1280px) {
  .contact-us-new .section__row {
    grid-gap: 80px;
  }

  .contact-us-new .col-testimonial {
    margin-bottom: -104px;
    margin-top: -104px;
    padding: 120px 40px 104px;
  }
}

@media (min-width: 1920px) {
  .contact-us-new .col-testimonial {
    margin-bottom: -144px;
    margin-top: -144px;
    padding-bottom: 144px;
    padding-top: 168px;
  }
}

@media (min-width: 2560px) {
  .testimonial-new__arrow {
    height: 56px;
  }

  .contact-us-new .section__row {
    grid-template-columns: 2fr 1fr;
  }

  .contact-us-new .col-testimonial {
    margin-bottom: -224px;
    margin-top: -224px;
    padding-bottom: 224px;
    padding-top: 248px;
  }
}

.header__menu {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header__menu.active {
  overflow: visible;
  transform: translateX(-100%);
}

.header__menu > li {
  margin-left: 60px;
}

@media (max-width: 1600px) {
  .header__menu > li {
    margin-left: 45px;
  }
}

@media (max-width: 1350px) {
  .header__menu > li {
    margin-left: 30px;
  }
}

@media (max-width: 1250px) {
  .header__menu > li {
    margin-left: 10px;
  }
}

.header__menu > li > .active.nav-link:after,
.header__menu > li > .maintainHover.nav-link:after {
  opacity: 1;
}

.header__menu > li > .active + .dropdown-menu__wrapper,
.header__menu > li > .maintainHover + .dropdown-menu__wrapper {
  -webkit-animation: 0.3s show-mobile-menu;
  animation: 0.3s show-mobile-menu;
  display: block;
}

.header__menu .nav-link {
  color: #373737;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  padding: 27px 10px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}

.header__menu .nav-link:after {
  background-color: #fff;
  top: 0;
  content: "";
  height: 3px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity 0.3s;
}

.header__menu > li > .dropdown-menu__wrapper {
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  display: none;
  margin-left: -20px;
  position: absolute;
  top: 100%;
  width: 180px;
}

.header__menu > li > .dropdown-menu__wrapper:after {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0,
    rgba(0, 0, 0, 0) 100%
  );
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.header__menu > li > .dropdown-menu__wrapper .dropdown-item {
  margin: 0;
  padding: 20px 30px;
}

@media (max-width: 1440px) {
  .header__menu > li > .dropdown-menu__wrapper .dropdown-item {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

@media (max-width: 960px) {
  .header__menu {
    display: none;
    overflow: auto;
    position: relative;
    transition: transform 0.3s;
    width: 100%;
  }

  .header__menu > li {
    margin-left: 0;
  }

  .header__menu > li:first-child {
    margin-top: 16px;
  }

  .header__menu .nav-link {
    color: #07393C;
    font-size: 1.143rem;
    line-height: 1.5;
    margin: 3px 0;
    padding: 12px 20px;
    transition-duration: 0.3s;
    transition-property: background-color, color;
  }

  .header__menu .nav-link:after {
    background-color: transparent !important;
    border-left: 1px solid #07393C;
    border-top: 1px solid #07393C;
    height: 6px;
    left: auto;
    opacity: 1;
    right: 28px;
    top: 21px;
    transform: rotate(135deg);
    transition-duration: 0.3s;
    transition-property: border-color, transform, top;
    width: 6px;
  }

  .header__menu .nav-link:before {
    background-color: #f9f9f9;
    border-radius: 50%;
    content: "";
    height: 20px;
    position: absolute;
    right: 20px;
    top: 14px;
    width: 20px;
  }

  .header__menu .nav-link:hover {
    background-color: #2C666E;
    color: #fff !important;
  }

  .header__menu .nav-link:hover:after {
    border-color: #2C666E;
  }

  .header__menu .nav-link.active + .dropdown-menu__wrapper {
    display: block;
  }

  .header__menu > li > .dropdown-menu__wrapper {
    box-shadow: none;
    position: static;
    margin-left: 0;
    width: 100%;
  }

  .header__menu > li > .dropdown-menu__wrapper:after {
    display: none;
  }

  .header__menu > li > .dropdown-menu__wrapper .dropdown-item {
    color: #373737;
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    padding: 8px 20px;
    position: relative;
    transition-duration: 0.3s;
    transition-property: font-weight, color;
  }

  .header__menu > li > .dropdown-menu__wrapper .dropdown-item:hover {
    color: #2C666E;
  }
}

.header__menu
  > li
  > .dropdown-menu__wrapper
  li:not(.menu-title)
  .dropdown-item {
  transition-duration: 0.3s;
  transition-property: color, font-weight;
}

.header__menu
  > li
  > .dropdown-menu__wrapper
  li:not(.menu-title)
  .dropdown-item:hover {
  color: #2C666E;
}

.header__menu > li.full-width > .dropdown-menu__wrapper {
  border-radius: 0;
  margin-left: 0;
  left: 0;
  width: 100%;
}

.header__menu > li.full-width .dropdown-menu {
  margin-left: 280px;
  padding: 50px 0;
}

@media (max-width: 1600px) {
  .header__menu > li.full-width .dropdown-menu {
    margin-left: 265px;
  }
}

@media (max-width: 1440px) {
  .header__menu > li.full-width .dropdown-menu {
    margin-left: 210px;
    padding-bottom: 25px;
    padding-top: 25px;
  }
}

@media (max-width: 1350px) {
  .header__menu > li.full-width .dropdown-menu {
    margin-left: 200px;
  }
}

@media (max-width: 1250px) {
  .header__menu > li.full-width .dropdown-menu {
    margin-left: 180px;
  }
}

@media (max-width: 1200px) {
  .header__menu > li.full-width .dropdown-menu {
    margin-left: 150px;
  }
}

@media (max-width: 1100px) {
  .header__menu > li.full-width .dropdown-menu {
    margin-left: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

@media (max-width: 960px) {
  .header__menu > li.full-width .dropdown-menu {
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.header__menu > li.full-width .dropdown-item {
  padding: 10px;
}

.header__menu > li.about-us > .dropdown-menu__wrapper > .dropdown-menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 70px;
  width: 37%;
}

@media (max-width: 1100px) {
  .header__menu > li.about-us > .dropdown-menu__wrapper > .dropdown-menu {
    width: 48%;
  }
}

.header__menu
  > li.about-us
  > .dropdown-menu__wrapper
  > .dropdown-menu
  .dropdown-menu {
  background-color: #f9f9f9;
  left: 100%;
  margin-left: 0;
  padding-left: 8%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.header__menu > li.about-us .dropdown-menu {
  height: 100%;
}

@media (max-width: 960px) {
  .header__menu > li.full-width .dropdown-item {
    padding: 8px 20px;
  }

  .header__menu > li.about-us > .dropdown-menu__wrapper > .dropdown-menu {
    display: block;
    padding-bottom: 0;
    width: 100%;
  }

  .header__menu > li .block-group > .dropdown-item {
    font-weight: 600;
    padding-bottom: 14px;
    padding-top: 14px;
  }

  .header__menu > li .block-group > .dropdown-item:after {
    border-left: 1px solid #07393C;
    border-top: 1px solid #07393C;
    content: "";
    height: 6px;
    position: absolute;
    right: 27px;
    top: 20px;
    transform: rotate(225deg);
    transition-duration: 0.3s;
    transition-property: border-color, transform, top;
    width: 6px;
  }

  .header__menu > li .block-group > .dropdown-item:before {
    background-color: #f9f9f9;
    border-radius: 50%;
    content: "";
    height: 20px;
    position: absolute;
    right: 20px;
    top: 14px;
    width: 20px;
  }

  .header__menu > li .block-group > .dropdown-item:hover {
    background-color: #2C666E;
    color: #fff;
  }

  .header__menu > li .block-group > .dropdown-item:hover:after {
    border-color: #2C666E;
  }

  .header__menu > li .block-group > .dropdown-item.active {
    background-color: #2C666E;
    color: #fff;
  }

  .header__menu > li .block-group > .dropdown-item.active:after {
    border-color: #2C666E;
    top: 22px;
    transform: rotate(45deg);
  }

  .header__menu
    > li
    .block-group
    > .dropdown-item.active
    + .dropdown-menu__wrapper {
    display: block;
  }

  .header__menu > li .block-group .dropdown-menu__wrapper {
    left: 0 !important;
    margin-bottom: 7px;
    margin-top: 7px;
    position: relative !important;
  }
}

.header__menu > li.services > .dropdown-menu__wrapper > .dropdown-menu {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding-bottom: 0;
  width: 70%;
}

@media (max-width: 1920px) {
  .header__menu > li.services > .dropdown-menu__wrapper > .dropdown-menu {
    width: 75%;
  }
}

@media (max-width: 1600px) {
  .header__menu > li.services > .dropdown-menu__wrapper > .dropdown-menu {
    width: 77%;
  }
}

@media (max-width: 1440px) {
  .header__menu > li.services > .dropdown-menu__wrapper > .dropdown-menu {
    width: 81%;
  }
}

@media (max-width: 1200px) {
  .header__menu > li.services > .dropdown-menu__wrapper > .dropdown-menu {
    width: 85%;
  }
}

@media (max-width: 1100px) {
  .header__menu > li.services > .dropdown-menu__wrapper > .dropdown-menu {
    width: 95%;
  }
}

.header__menu
  > li.services
  > .dropdown-menu__wrapper
  > .dropdown-menu
  .dropdown-menu {
  margin-left: 0;
}

.header__menu > li.services .block-group .dropdown-menu {
  padding-top: 0;
}

.header__menu > li.services .block-group .dropdown-menu .dropdown-item {
  padding: 5px 0 5px 10px;
  position: relative;
}

@media (max-width: 960px) {
  .header__menu > li.services > .dropdown-menu__wrapper > .dropdown-menu {
    display: block;
    width: 100%;
  }

  .header__menu
    > li.services
    > .dropdown-menu__wrapper
    > .dropdown-menu
    .dropdown-menu
    .dropdown-item {
    padding-left: 40px;
  }

  .header__menu
    > li.services
    > .dropdown-menu__wrapper
    > .dropdown-menu
    .dropdown-menu
    .dropdown-item:after {
    left: 20px;
    top: 16px;
  }

  .header__menu > li.services .block-group .dropdown-menu .dropdown-item {
    padding: 8px 20px;
  }

  .header__menu > li.services .block-group .submenu .dropdown-menu__wrapper {
    display: block;
  }
}

.header__menu
  > li.services
  .block-group
  .submenu
  .dropdown-menu
  .dropdown-item {
  padding-left: 36px;
}

.header__menu
  > li.services
  .block-group
  .submenu
  .dropdown-menu
  .dropdown-item:before {
  background-color: #2C666E;
  content: "";
  height: 1px;
  left: 10px;
  position: absolute;
  top: 50%;
  width: 6px;
}

.header__menu > li .submenu .dropdown-menu {
  padding-bottom: 0;
}

@media (max-width: 960px) {
  .header__menu
    > li.services
    .block-group
    .submenu
    .dropdown-menu
    .dropdown-item {
    padding-left: 60px;
  }

  .header__menu
    > li.services
    .block-group
    .submenu
    .dropdown-menu
    .dropdown-item:before {
    left: 40px;
  }

  .header__menu .dropdown-menu__wrapper {
    display: none;
    position: absolute !important;
    left: 100% !important;
    top: 0 !important;
    bottom: 0;
    width: 100%;
    overflow: auto;
  }
}

.header__menu .dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.header__menu .menu-title > .dropdown-item {
  cursor: default;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.header__menu .dropdown-item {
  color: #373737;
  display: block;
  margin-bottom: 5px;
  padding: 5px 20px;
  text-decoration: none;
}

.header__menu .back-item {
  display: none;
}

.header__menu .back-item > .dropdown-item {
  background-color: #f8f8f9;
  color: #2C666E !important;
  position: relative;
  padding: 14px 20px 15px 40px !important;
}

.header__menu .back-item > .dropdown-item:after {
  border-left: 1px solid #2C666E;
  border-top: 1px solid #2C666E;
  content: "";
  height: 14px;
  left: 20px;
  position: absolute;
  top: 17px;
  transform: rotate(-45deg);
  transition-duration: 0.3s;
  transition-property: border-color, transform, top;
  width: 14px;
}

.header__search {
  position: relative;
}

@media (min-width: 961px) {
  .home .header__menu .nav-link {
    color: #fff;
  }
}

.header--stick .header__menu .nav-link,
.home.header--stick .header__menu .nav-link {
  color: #07393C;
}

.header--stick .header__menu .nav-link:after,
.home.header--stick .header__menu .nav-link:after {
  background-color: #2C666E;
}

.header--stick .header__menu .nav-link:hover,
.home.header--stick .header__menu .nav-link:hover {
  color: #2C666E;
}

.header--white .header__menu .nav-link,
.home.header--white .header__menu .nav-link {
  color: #07393C;
}

.header--white .header__menu .nav-link:after,
.home.header--white .header__menu .nav-link:after {
  background-color: #2C666E;
}

.header--white .header__menu .nav-link:hover,
.home.header--white .header__menu .nav-link:hover {
  color: #2C666E;
}

@media (max-width: 960px) {
  .header__menu .back-item {
    display: block;
  }

  .mobile-menu-active .header__menu {
    display: block;
  }
}

@-webkit-keyframes show-mobile-menu {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-mobile-menu {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer--new__logo:focus-visible:after,
.header--new__logo:focus-visible .header--new__logo-img-wrap:after {
  opacity: 1;
}

.no-js .header__menu > li:hover .dropdown-menu__wrapper {
  display: block;
}

.header--new {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.8);
  content-visibility: initial;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color 0.3s;
  z-index: 6;
}

.header--new__row {
  align-items: center;
  border-bottom: 1px solid #c7c7d6;
  display: flex;
  padding-bottom: 8px;
  padding-top: 8px;
}

.header--new__logo {
  align-items: center;
  align-self: stretch;
  display: flex;
  outline: 0;
  position: relative;
}

.header--new__logo-img {
  display: block;
  fill: #2C666E;
  height: 20px;
  vertical-align: top;
  width: auto;
}

@media (min-width: 768px) {
  .header--new__row {
    padding-bottom: 12px;
    padding-top: 12px;
  }

  .header--new__logo-img {
    height: 24px;
  }
}

@media (min-width: 2560px) {
  .header__menu > li.full-width .dropdown-menu {
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
  }

  .header--new__logo-img {
    height: 40px;
  }
}

.header--new__logo-img-wrap {
  display: block;
  position: relative;
}

.header--new__logo-img-wrap:after {
  border-radius: 20px;
  border: 2px solid #2C666E;
  bottom: -7px;
  content: "";
  left: -11px;
  opacity: 0;
  position: absolute;
  right: -11px;
  top: -4px;
  transition: opacity 0.1s cubic-bezier(0.4, 0.2, 0.4, 1);
}

.header--new__info {
  align-items: center;
  display: none;
}

@media (min-width: 961px) {
  .header--new__row {
    padding-bottom: 0;
    padding-top: 0;
  }

  .header--new__info {
    display: flex;
    flex: 1;
  }
}

.header--new__btn--desktop {
  display: none;
}

@media (min-width: 961px) {
  .header--new__btn--desktop {
    display: inline-flex;
    margin-left: 8px;
  }

  .header--new__btn--mobile {
    display: none;
  }
}

.header--new__btn--mobile {
  margin-left: auto;
}

.header--new__btn--mobile:after,
.header--new__btn--mobile:before {
  transition: filter 0.3s;
}

.header--new__mask {
  height: 72px;
}

@media (min-width: 768px) {
  .header--new__mask {
    height: 80px;
  }
}

.header--new:has(.search--new__icon-wrap.active) ~ .scroll-to-top {
  display: none;
}

.header--new__screen-reader-shortcut {
  align-items: center;
  background-color: #2C666E;
  color: #fff;
  display: inline-flex;
  font-size: 16px;
  font-weight: 700;
  gap: 8px;
  justify-content: center;
  left: 0;
  line-height: 24px;
  outline: 0;
  position: fixed;
  right: 0;
  text-decoration: none;
  transition: transform 0.3s;
  transform: translateY(-101%);
  top: 0;
  z-index: 7;
}

.header--new__screen-reader-shortcut:focus-visible {
  transform: translateY(0);
}

.header__menu--new {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header__menu--newWrap {
  margin-right: auto;
}

@media (min-width: 961px) {
  .header__menu--new {
    align-items: center;
    display: flex;
  }

  .header__menu--new::after {
    background-color: rgba(0, 0, 0, 0.48);
    display: none;
    content: "";
    left: 0;
    margin-top: -1px;
    min-height: 100vh;
    position: absolute;
    right: 0;
    top: 100%;
  }

  .header__menu--new:has(.menu-item-has-children:hover)::after {
    -webkit-animation: 0.1s fade-anim;
    animation: 0.1s fade-anim;
    display: block;
  }

  .header__menu--newWrap {
    margin-left: 46px;
  }

  .header__menu--new > li > .dropdown-menu__wrapper {
    display: none;
    left: 0;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -1px;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1;
  }

  .header__menu--new > li > .dropdown-menu__wrapper > .dropdown-menu {
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

@media (min-width: 1280px) {
  .header--new__mask {
    height: 86px;
  }

  .header__menu--newWrap {
    margin-left: 102px;
  }
}

@media (min-width: 1440px) {
  .header--new__mask {
    height: 112px;
  }

  .header__menu--newWrap {
    margin-left: 114px;
  }
}

@media (min-width: 1280px) {
  .header__menu--new > li > .dropdown-menu__wrapper {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (min-width: 1600px) {
  .header__menu--new > li > .dropdown-menu__wrapper {
    margin-left: 168px;
    margin-right: 168px;
  }
}

@media (min-width: 1920px) {
  .header__menu--newWrap {
    margin-left: 196px;
  }

  .header__menu--new > li > .dropdown-menu__wrapper {
    margin-left: 208px;
    margin-right: 208px;
  }
}

@media (min-width: 2560px) {
  .header--new__mask {
    height: 136px;
  }

  .header__menu--newWrap {
    margin-left: 222px;
  }

  .header__menu--new > li > .dropdown-menu__wrapper {
    margin-left: 286px;
    margin-right: 286px;
  }
}

@media (min-width: 2956px) {
  .header__menu--new > li > .dropdown-menu__wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 2384px;
  }
}

.header__menu--new .nav-link {
  display: flex;
  outline: 0;
  position: relative;
  text-decoration: none;
}

@media (min-width: 961px) {
  .header__menu--new > li.hover .dropdown-menu__wrapper,
  .header__menu--new > li:hover .dropdown-menu__wrapper {
    -webkit-animation: 0.5s fade-anim;
    animation: 0.5s fade-anim;
    display: grid;
  }

  .header__menu--new > li.active .menu-title:before,
  .header__menu--new > li.hover .menu-title:before,
  .header__menu--new > li:hover .menu-title:before {
    opacity: 1;
    transform: translateY(0);
  }

  .header__menu--new .nav-link {
    padding: 20px;
  }

  .header__menu--new .nav-link:focus-visible .menu-title:after {
    opacity: 1;
  }

  .header__menu--new .nav-link .menu-title {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .header__menu--new .nav-link .menu-title:after {
    border-radius: 20px;
    border: 2px solid #2C666E;
    bottom: -2px;
    content: "";
    left: -11px;
    opacity: 0;
    position: absolute;
    right: -11px;
    top: -3px;
    transition: opacity 0.1s cubic-bezier(0.4, 0.2, 0.4, 1);
  }

  .header__menu--new .nav-link .menu-title:before {
    background-color: #2C666E;
    border-radius: 50%;
    bottom: -10px;
    content: "";
    height: 8px;
    left: 50%;
    margin-left: -4px;
    opacity: 0;
    position: absolute;
    transition-duration: 0.3s;
    transition-property: opacity, transform;
    transform: translateY(8px);
    width: 8px;
  }

  .header__menu--new .dropdown-menu-opener {
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 3L4.5 7L0.500001 3' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid #2C666E;
    border-radius: 16px;
    height: 29px;
    width: 21px;
    position: absolute;
    flex: 0 0 21px;
    outline: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.1s cubic-bezier(0.4, 0.2, 0.4, 1);
  }
}

@media (min-width: 1280px) {
  .header__menu--new .nav-link {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .header__menu--new .nav-link .menu-title:before {
    bottom: -12px;
  }
}

@media (min-width: 1440px) {
  .header__menu--new .nav-link {
    padding: 32px 24px;
  }

  .header__menu--new .nav-link .menu-title {
    font-size: 16px;
  }

  .header__menu--new .nav-link .menu-title:before {
    bottom: -16px;
  }
}

@media (min-width: 2560px) {
  .header__menu--new .nav-link {
    padding: 36px;
  }

  .header__menu--new .nav-link .menu-title {
    font-size: 20px;
    line-height: 32px;
  }

  .header__menu--new .nav-link .menu-title:before {
    bottom: -18px;
  }
}

@media (min-width: 961px) and (min-width: 2560px) {
  .header__menu--new .dropdown-menu-opener {
    flex-basis: 26px;
    height: 37px;
    width: 26px;
  }
}

.header__menu--new .icon {
  height: 56px;
  transition: filter 0.3s;
  width: auto;
}

.header__menu--new .icon-wrap {
  display: none;
}

@media (min-width: 961px) {
  .header__menu--new .dropdown-menu-opener:focus-visible {
    opacity: 1;
  }

  .header__menu--new .icon-wrap {
    align-items: center;
    background-color: #F0EDEE;
    border-radius: 8px;
    border: 1px solid #F0EDEE;
    display: flex;
    flex: 0 0 56px;
    height: 56px;
    justify-content: center;
    overflow: hidden;
    width: 56px;
    transition-duration: 0.3s;
    transition-property: background-color, border-color;
  }
}

.header__menu--new .description {
  display: none;
}

@media (min-width: 961px) {
  .header__menu--new .description {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #54575e;
    display: -webkit-box;
    font-size: 12px;
    font-weight: 400;
    height: 42px;
    line-height: 14px;
    margin: 0;
    max-width: 360px;
    overflow: hidden;
    text-align: left;
  }
}

.header__menu--new .image {
  display: block;
  width: 100%;
}

.header__menu--new .image-wrap {
  display: none;
}

.header__menu--new .dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.header__menu--new .dropdown-item {
  display: block;
  outline: 0;
  text-decoration: none;
}

@media (min-width: 961px) {
  .header__menu--new .image-wrap {
    display: block;
  }

  .header__menu--new .dropdown-menu a > .menu-title {
    padding-right: 25px;
    position: relative;
    transition-duration: 0.3s;
    transition-property: color, padding-left, padding-right;
  }

  .header__menu--new .dropdown-menu a > .menu-title:after {
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='24' viewBox='0 0 17 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 20L16 12M8 4L16 12M16 12L-6.99382e-07 12' stroke='%230045E6'/%3E%3C/svg%3E");
    content: "";
    height: 24px;
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s;
    width: 17px;
    left: 0;
    top: 0;
  }

  .header__menu--new .dropdown-menu a:focus-visible > .menu-title,
  .header__menu--new .dropdown-menu a:hover > .menu-title {
    color: #2C666E;
    padding-left: 25px;
    padding-right: 0;
  }

  .header__menu--new .dropdown-menu a:focus-visible > .menu-title:after,
  .header__menu--new .dropdown-menu a:hover > .menu-title:after {
    opacity: 1;
  }

  .header__menu--new .dropdown-menu a:focus-visible > .icon-wrap,
  .header__menu--new .dropdown-menu a:hover > .icon-wrap {
    background-color: #2C666E;
    border-color: #2C666E;
  }

  .header__menu--new .dropdown-menu a:focus-visible > .icon-wrap .icon,
  .header__menu--new .dropdown-menu a:hover > .icon-wrap .icon {
    filter: brightness(0) invert(1);
  }

  .header__menu--new .services .block-group {
    padding: 8px 8px 0;
    width: 20%;
  }

  .header__menu--new .services .block-group:last-child {
    padding-bottom: 8px;
  }

  .header__menu--new .services .block-group.active > .dropdown-item,
  .header__menu--new .services .block-group:hover > .dropdown-item {
    background-color: #fff;
    color: #07393C;
  }

  .header__menu--new
    .services
    .block-group.active
    > .dropdown-item
    .description,
  .header__menu--new
    .services
    .block-group:hover
    > .dropdown-item
    .description {
    color: #373737;
  }

  .header__menu--new .services .block-group.active > .dropdown-menu__wrapper,
  .header__menu--new .services .block-group:hover > .dropdown-menu__wrapper {
    -webkit-animation: 0.5s fade-anim;
    animation: 0.5s fade-anim;
    display: block;
  }

  .header__menu--new
    .services
    .block-group.active
    > .dropdown-menu__wrapper
    .dropdown-item,
  .header__menu--new
    .services
    .block-group:hover
    > .dropdown-menu__wrapper
    .dropdown-item {
    -webkit-animation: 0.5s menu-anim;
    animation: 0.5s menu-anim;
  }

  .header__menu--new .services .block-group > .dropdown-item {
    border-radius: 8px;
    color: #fff;
    display: block;
    min-height: 86px;
    padding: 8px;
    transition: background-color 0.3s;
  }

  .header__menu--new .services .block-group > .dropdown-item:focus-visible {
    outline-offset: 1px;
    outline: #2C666E solid 2px;
  }

  .header__menu--new .services .block-group > .dropdown-item > .menu-title {
    margin-bottom: 8px;
  }

  .header__menu--new .services .block-group > .dropdown-item > .description {
    color: #c7c7d6;
  }

  .header__menu--new .services .block-group .dropdown-menu {
    background-color: #fff;
    bottom: 0;
    display: grid;
    grid-template-rows: 1fr 1fr;
    left: 20%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  .header__menu--new .services .block-group .dropdown-menu > .menu-item {
    border: 1px solid #c7c7d6;
    margin: 0 -1px -1px 0;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item
    > .dropdown-item {
    padding: 16px;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item
    .icon-wrap {
    margin-bottom: 24px;
  }
}

.header__menu--new .services .block-group .dropdown-menu__wrapper {
  display: none;
}

.header__menu--new
  .services
  .block-group
  .dropdown-menu
  > .menu-item
  > .dropdown-item {
  text-decoration: none;
}

@media (min-width: 1280px) {
  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item
    > .dropdown-item {
    padding: 24px;
  }
}

@media (min-width: 1440px) {
  .header__menu--new .description {
    font-size: 14px;
    height: 48px;
    line-height: 16px;
  }

  .header__menu--new .services .block-group > .dropdown-item {
    min-height: 106px;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item
    > .dropdown-item {
    padding: 40px;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item
    .icon-wrap {
    margin-bottom: 38px;
  }
}

.header__menu--new .services .data-ai .ai-menu-item {
  grid-row: span 2;
  position: relative;
}

.header__menu--new .industries .description,
.header__menu--new .industries .image-wrap,
.header__menu--new .industries .long-title {
  display: none;
}

@media (min-width: 961px) {
  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item
    .menu-title {
    min-height: 40px;
    margin-bottom: 14px;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu {
    display: block;
    position: static;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu__wrapper {
    display: block;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu
    .menu-item {
    border: none;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu
    .menu-item
    .dropdown-item {
    padding-bottom: 0;
    padding-top: 0;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu
    .menu-item
    .menu-title {
    color: #9b9fad;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu
    .menu-item
    .menu-title:hover {
    color: #2C666E;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item.submenu
    > .dropdown-item {
    padding-bottom: 0;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item.submenu
    .menu-title {
    margin-bottom: 8px;
    min-height: initial;
  }

  .header__menu--new .services .advisory .dropdown-menu,
  .header__menu--new .services .engineering .dropdown-menu {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .header__menu--new .services .data-ai .dropdown-menu {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .header__menu--new .services .data-ai .ai-menu-item .description {
    left: 16px;
    position: absolute;
    right: 16px;
    top: 389px;
  }

  .header__menu--new .services .optimisation .dropdown-menu {
    grid-template-columns: 1fr 1fr;
  }

  .header__menu--new .services .expertise .dropdown-menu {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .header__menu--new .services > .dropdown-menu__wrapper > .dropdown-menu {
    background: linear-gradient(
      90deg,
      #07393C 0,
      #07393C 20%,
      #fff 20%,
      #fff 100%
    );
    position: relative;
  }

  .header__menu--new .industries > .dropdown-menu__wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .header__menu--new .industries > .dropdown-menu__wrapper .dropdown-menu {
    background-color: #07393C;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom-right-radius: 0;
    color: #fff;
  }

  .header__menu--new .industries .menu-title {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .header__menu--new .industries .menu-title:after {
    display: none;
  }

  .header__menu--new .industries .icon {
    filter: brightness(0) invert(1);
  }

  .header__menu--new .industries .icon-wrap {
    border-color: #fff;
    background-color: transparent;
  }

  .header__menu--new .industries .dropdown-item {
    align-items: center;
    background-clip: padding-box;
    border-radius: 16px;
    border: 8px solid transparent;
    display: flex;
    gap: 16px;
    padding: 8px;
    transition: background-color 0.3s;
  }

  .header__menu--new .industries .dropdown-item:focus-visible,
  .header__menu--new .industries .dropdown-item:hover {
    background-color: #fff;
    color: #07393C;
  }

  .header__menu--new .industries .dropdown-item:focus-visible .icon,
  .header__menu--new .industries .dropdown-item:hover .icon {
    filter: none !important;
  }

  .header__menu--new .industries .dropdown-item:focus-visible .icon-wrap,
  .header__menu--new .industries .dropdown-item:hover .icon-wrap {
    border-color: #F0EDEE;
    background-color: #F0EDEE;
  }

  .header__menu--new .industries .dropdown-item:focus-visible .menu-title,
  .header__menu--new .industries .dropdown-item:hover .menu-title {
    color: #07393C !important;
  }

  .header__menu--new .industries__holder {
    background-color: #fff;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #c7c7d6;
  }

  .header__menu--new .industries__holder .image-wrap,
  .header__menu--new .industries__holder .long-title {
    display: block;
  }

  .header__menu--new .industries__holder-item {
    display: none;
    flex-direction: column;
    height: 100%;
    padding: 16px;
  }

  .header__menu--new .industries__holder-item .long-title {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 16px;
  }
}

@media (min-width: 1280px) {
  .header__menu--new .services .data-ai .ai-menu-item .description {
    left: 24px;
    right: 24px;
    top: 407px;
  }

  .header__menu--new .industries .dropdown-item {
    gap: 24px;
    padding: 12px;
  }
}

@media (min-width: 961px) {
  .header__menu--new .industries__holder-item .description {
    display: -webkit-box;
    margin-bottom: 24px;
    max-width: 356px;
  }

  .header__menu--new .industries__holder-item .image {
    aspect-ratio: 2;
    border-radius: 8px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media (min-width: 1280px) {
  .header__menu--new .industries__holder-item {
    padding: 24px;
  }

  .header__menu--new .industries__holder-item .long-title {
    font-size: 28px;
    line-height: 34px;
  }

  .header__menu--new .industries__holder-item .description {
    margin-bottom: 32px;
  }
}

@media (min-width: 1440px) {
  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item
    .menu-title {
    margin-bottom: 18px;
    min-height: 48px;
  }

  .header__menu--new .services .data-ai .ai-menu-item .description {
    left: 40px;
    right: 40px;
    top: 489px;
  }

  .header__menu--new .industries .dropdown-item {
    padding: 20px;
  }

  .header__menu--new .industries__holder-item {
    padding: 40px;
  }

  .header__menu--new .industries__holder-item .long-title {
    font-size: 32px;
    line-height: 40px;
  }

  .header__menu--new .industries__holder-item .description {
    margin-bottom: 42px;
  }
}

@media (min-width: 961px) {
  .header__menu--new .industries__holder-item .image-wrap {
    flex: 1;
    margin-bottom: -8px;
    margin-left: -8px;
    margin-right: -8px;
  }

  .header__menu--new .industries__holder-item.active {
    display: flex;
  }

  .header__menu--new .industries__holder-item.active .description,
  .header__menu--new .industries__holder-item.active .long-title {
    -webkit-animation: 0.5s translate-anim;
    animation: 0.5s translate-anim;
  }

  .header__menu--new .industries__holder-item.active .image {
    -webkit-animation: 0.5s fade-anim;
    animation: 0.5s fade-anim;
  }

  .header__menu--new .clients .dropdown-menu {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item {
    border: 1px solid #c7c7d6;
    margin: 0 -1px -1px 0;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item > .dropdown-item {
    padding: 16px;
    text-decoration: none;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item .menu-title {
    min-height: 40px;
    margin-bottom: 14px;
  }
}

@media (min-width: 1280px) {
  .header__menu--new .industries__holder-item .image-wrap {
    margin-bottom: -12px;
    margin-left: -12px;
    margin-right: -12px;
  }
}

@media (min-width: 1280px) {
  .header__menu--new .clients .dropdown-menu > .menu-item > .dropdown-item {
    padding: 24px;
  }
}

@media (min-width: 1440px) {
  .header__menu--new .industries__holder-item .image-wrap {
    margin-bottom: -24px;
    margin-left: -24px;
    margin-right: -24px;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item > .dropdown-item {
    padding: 40px;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item .menu-title {
    margin-bottom: 18px;
    min-height: 48px;
  }
}

@media (min-width: 2560px) {
  .header__menu--new .dropdown-menu a > .menu-title:after {
    top: 3px;
  }

  .header__menu--new .services .block-group > .dropdown-item {
    min-height: 113px;
  }

  .header__menu--new
    .services
    .block-group
    .dropdown-menu
    > .menu-item
    .menu-title {
    min-height: 64px;
  }

  .header__menu--new .services .data-ai .ai-menu-item .description {
    top: 524px;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item .menu-title {
    min-height: 64px;
  }
}

@media (min-width: 961px) {
  .header__menu--new .clients .dropdown-menu > .menu-item .description {
    margin-bottom: 36px;
  }
}

@media (min-width: 1440px) {
  .header__menu--new .clients .dropdown-menu > .menu-item .description {
    margin-bottom: 56px;
  }
}

@media (min-width: 961px) {
  .header__menu--new .clients .dropdown-menu > .menu-item .image-wrap {
    aspect-ratio: 2;
    background-color: #f8f8f9;
    border-radius: 8px;
    margin-bottom: -8px;
    margin-left: -8px;
    margin-right: -8px;
    overflow: hidden;
    padding-left: 24px;
    padding-right: 24px;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item.submenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 16px;
  }

  .header__menu--new
    .clients
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu {
    display: block;
  }

  .header__menu--new
    .clients
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu
    .menu-item {
    border: none;
  }

  .header__menu--new
    .clients
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu
    .menu-item
    .dropdown-item {
    padding-bottom: 0;
    padding-top: 0;
  }

  .header__menu--new
    .clients
    .dropdown-menu
    > .menu-item.submenu
    > .dropdown-item {
    padding-bottom: 0;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item.submenu .menu-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 0;
    margin-top: 24px;
    min-height: initial;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item.submenu .description {
    height: auto;
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .header__menu--new .clients .dropdown-menu > .menu-item .image-wrap {
    margin-bottom: -12px;
    margin-left: -12px;
    margin-right: -12px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (min-width: 1280px) {
  .header__menu--new .clients .dropdown-menu > .menu-item.submenu .menu-title {
    font-size: 30px;
    line-height: 38px;
    margin-top: 32px;
  }
}

@media (min-width: 1440px) {
  .header__menu--new .clients .dropdown-menu > .menu-item .image-wrap {
    margin-bottom: -24px;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 44px;
    padding-right: 44px;
  }

  .header__menu--new .clients .dropdown-menu > .menu-item.submenu .menu-title {
    font-size: 40px;
    line-height: 40px;
    margin-top: 36px;
  }
}

.header__menu--new .about-us .dropdown-menu > .menu-item > .dropdown-item {
  text-decoration: none;
}

@media (min-width: 961px) {
  .header__menu--new .clients .dropdown-menu .clients-item .image {
    flex: 0 0 22.33%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center top;
    object-position: center top;
    order: 1;
    transition: object-position 1s;
    transition: object-position 1s, -o-object-position 1s;
  }

  .header__menu--new .clients .dropdown-menu .clients-item .image-wrap {
    display: flex;
    gap: 16%;
  }

  .header__menu--new .clients .dropdown-menu .clients-item .image-wrap:before {
    background-image: url(./assets/images/menu-clients-image-2.svg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    flex: 0 0 22.33%;
    order: 2;
    transition: background-position 1s;
  }

  .header__menu--new .clients .dropdown-menu .clients-item .image-wrap:after {
    background-image: url(./assets/images/menu-clients-image-3.svg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    flex: 0 0 22.33%;
    order: 3;
    transition: background-position 1s;
  }

  .header__menu--new .clients .dropdown-menu .clients-item:hover .image {
    -o-object-position: center bottom;
    object-position: center bottom;
  }

  .header__menu--new
    .clients
    .dropdown-menu
    .clients-item:hover
    .image-wrap:after,
  .header__menu--new
    .clients
    .dropdown-menu
    .clients-item:hover
    .image-wrap:before {
    background-position: center bottom;
  }

  .header__menu--new .clients .dropdown-menu .case-studies-item .image {
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center top;
    object-position: center top;
    transition: object-position 2.5s;
    transition: object-position 2.5s, -o-object-position 2.5s;
    width: 100%;
  }

  .header__menu--new .clients .dropdown-menu .case-studies-item .image-wrap {
    display: flex;
  }

  .header__menu--new .clients .dropdown-menu .case-studies-item:hover .image {
    -o-object-position: center bottom;
    object-position: center bottom;
  }

  .header__menu--new .about-us .dropdown-menu {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .header__menu--new .about-us .dropdown-menu > .menu-item {
    border: 1px solid #c7c7d6;
    margin: 0 -1px -1px 0;
  }

  .header__menu--new .about-us .dropdown-menu > .menu-item > .dropdown-item {
    padding: 16px;
  }

  .header__menu--new .about-us .dropdown-menu > .menu-item .menu-title {
    min-height: 40px;
    margin-bottom: 14px;
  }
}

@media (min-width: 1280px) {
  .header__menu--new .about-us .dropdown-menu > .menu-item > .dropdown-item {
    padding: 24px;
  }
}

@media (min-width: 1440px) {
  .header__menu--new .about-us .dropdown-menu > .menu-item > .dropdown-item {
    padding: 40px;
  }

  .header__menu--new .about-us .dropdown-menu > .menu-item .menu-title {
    margin-bottom: 18px;
    min-height: 48px;
  }
}

@media (min-width: 961px) {
  .header__menu--new .about-us .dropdown-menu > .menu-item .description {
    margin-bottom: 64px;
  }

  .header__menu--new
    .about-us
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu {
    display: block;
  }

  .header__menu--new
    .about-us
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu
    .menu-item {
    border: none;
  }

  .header__menu--new
    .about-us
    .dropdown-menu
    > .menu-item.submenu
    .dropdown-menu
    .menu-item
    .dropdown-item {
    padding-bottom: 0;
    padding-top: 0;
  }

  .header__menu--new
    .about-us
    .dropdown-menu
    > .menu-item.submenu
    > .dropdown-item {
    padding-bottom: 0;
  }

  .header__menu--new .about-us .dropdown-menu > .menu-item.submenu .menu-title {
    margin-bottom: 16px;
    min-height: initial;
  }

  .header__menu--new .about-us .big-size {
    grid-row: span 2;
  }

  .header__menu--new .about-us .with-icon > .dropdown-item {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: space-between;
  }

  .header__menu--new .about-us .image-wrap {
    margin-bottom: -8px;
    margin-left: -8px;
    margin-right: -8px;
  }

  .hide-show-menu {
    display: none;
  }
}

@media (min-width: 1280px) {
  .header__menu--new .about-us .image-wrap {
    margin-bottom: -12px;
    margin-left: -12px;
    margin-right: -12px;
  }
}

@media (min-width: 1440px) {
  .header__menu--new .about-us .dropdown-menu > .menu-item .description {
    margin-bottom: 128px;
  }

  .header__menu--new .about-us .image-wrap {
    margin-bottom: -24px;
    margin-left: -24px;
    margin-right: -24px;
  }
}

.hide-show-menu {
  background-color: #e9edf3;
  border-radius: 40px;
  cursor: pointer;
  margin-left: 8px;
  padding: 15px 20px 14px;
}

.hide-show-menu:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.hide-show-menu-icon {
  border-color: #07393C;
  border-style: solid;
  border-width: 1px 0;
  height: 11px;
  position: relative;
  transition: all 0.15s cubic-bezier(0.67, -0.03, 0.45, 1.89);
  width: 16px;
}

.hide-show-menu-icon::after,
.hide-show-menu-icon::before {
  background-color: #07393C;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 4px;
  transition: all 0.35s cubic-bezier(0.67, -0.03, 0.45, 1.89);
  width: 16px;
}

.mobile-menu-active {
  overflow: hidden;
}

.mobile-menu-active .hide-show-menu {
  background-color: #2c2a39;
}

.mobile-menu-active .hide-show-menu-icon {
  border-width: 0;
  margin-left: -1px;
  transform: rotate(90deg);
}

.mobile-menu-active .hide-show-menu-icon::after,
.mobile-menu-active .hide-show-menu-icon::before {
  background-color: #fff;
}

.mobile-menu-active .hide-show-menu-icon::after {
  transform: rotate(45deg);
}

.mobile-menu-active .hide-show-menu-icon::before {
  transform: rotate(-45deg);
}

.mobile-menu-active .header--new {
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  background-color: #07393C;
}

.mobile-menu-active .header--new__row {
  border-bottom-color: #54575e;
}

.mobile-menu-active .header--new__logo-img {
  fill: #fff;
}

.mobile-menu-active .header--new__btn--mobile {
  background-color: #fff;
  color: #07393C;
}

.mobile-menu-active .header--new__btn--mobile:after,
.mobile-menu-active .header--new__btn--mobile:before {
  filter: brightness(1);
}

.mobile-menu-active .header--new__info {
  align-items: flex-start;
  background-color: #07393C;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0;
  overflow: auto;
  padding: 8px 8px 52px;
  position: fixed;
  right: 0;
  top: 57px;
}

.mobile-menu-active .header__menu--newWrap {
  flex: 0 0 100%;
  margin-bottom: 48px;
}

.mobile-menu-active .header__menu--new > .menu-item {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 4px;
}

.mobile-menu-active
  .header__menu--new
  > .menu-item.nav-link:focus-visible
  .menu-title {
  background-color: red;
}

.mobile-menu-active
  .header__menu--new
  > .menu-item-has-children
  .block-group
  > .dropdown-item:after,
.mobile-menu-active
  .header__menu--new
  > .menu-item-has-children
  .nav-link:after {
  content: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L5 8M9 4L5 8M5 8L5 1.74846e-07' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
  height: 20px;
  margin-right: 8px;
  transition: transform 0.3s;
  transform-origin: center;
}

.mobile-menu-active .header__menu--new .nav-link {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.mobile-menu-active .header__menu--new .nav-link .menu-title {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}

.mobile-menu-active .header__menu--new .nav-link.active:after {
  transform: rotate(180deg);
}

.mobile-menu-active .header__menu--new .block-group {
  transition: background-color 0.3s;
}

.mobile-menu-active .header__menu--new .block-group > .dropdown-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.mobile-menu-active .header__menu--new .block-group.active {
  background-color: #07393C;
  border-radius: 4px;
  color: #fff;
  margin-left: 4px;
  margin-right: 4px;
}

.mobile-menu-active
  .header__menu--new
  .block-group.active
  > .dropdown-item:after {
  filter: brightness(0) invert(1);
  transform: rotate(180deg);
  transition-duration: 0.3s;
  transition-property: filter, transform;
}

.footer__menu li a {
  transition-duration: 0.3s;
  transition-property: border-color, color;
  text-decoration: none;
}

.mobile-menu-active .header__menu--new .block-group.active .dropdown-item {
  padding-left: 12px;
  padding-right: 12px;
}

.mobile-menu-active
  .header__menu--new
  .block-group
  .dropdown-menu__wrapper
  .menu-title {
  font-weight: 400;
}

.mobile-menu-active
  .header__menu--new
  .block-group
  .dropdown-menu__wrapper
  .dropdown-menu__wrapper {
  display: block;
}

.mobile-menu-active
  .header__menu--new
  .block-group
  .dropdown-menu__wrapper
  .dropdown-menu__wrapper
  .dropdown-menu {
  padding-left: 32px;
}

.mobile-menu-active
  .header__menu--new
  .block-group
  .dropdown-menu__wrapper
  .dropdown-menu__wrapper
  .dropdown-menu
  .menu-title {
  color: #c7c7d6;
}

.mobile-menu-active .header__menu--new .dropdown-menu__wrapper {
  display: none;
}

.mobile-menu-active .header__menu--new .dropdown-item {
  padding: 10px 16px;
}

.mobile-menu-active .header__menu--new .services .nav-link {
  border-bottom: 1px solid transparent;
}

.mobile-menu-active .header__menu--new .services .nav-link.active {
  border-bottom-color: #c7c7d6;
}

.mobile-menu-active .header__menu--new .about-us .dropdown-menu {
  display: flex;
  flex-direction: column;
}

.mobile-menu-active .header__menu--new .about-us .submenu {
  order: 1;
}

.mobile-menu-active
  .header__menu--new
  .about-us
  .submenu
  .dropdown-menu__wrapper {
  display: block;
}

.mobile-menu-active .header__menu--new .clients .submenu,
.mobile-menu-active .scroll-to-top {
  display: none;
}

@-webkit-keyframes fade-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes menu-anim {
  0% {
    transform: translateY(16px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes menu-anim {
  0% {
    transform: translateY(16px);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes translate-anim {
  0% {
    transform: translateY(16px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes translate-anim {
  0% {
    transform: translateY(16px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer__menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__menu li a {
  border-bottom: 1px solid transparent;
  color: #373737;
  font-size: 1rem;
  display: inline-block;
}

.footer__menu li a:hover {
  border-bottom-color: #07393C;
  color: #07393C;
}

.footer__menu li + li {
  margin-top: 1rem;
}

.footer__closest-office {
  max-width: 450px;
}

@media (max-width: 1700px) {
  .footer__closest-office {
    max-width: 430px;
  }
}

.footer--new__lang .lang--new__item.selected,
.footer--new__lang .lang--new__link:hover {
  color: #2C666E;
}

.footer__lang {
  margin-left: 40px;
}

@media (max-width: 480px) {
  .form .formrow--checkbox {
    grid-column: span 1;
  }

  .footer__lang {
    margin-left: 20px;
  }
}

@media (max-width: 400px) {
  .footer__lang {
    display: none;
  }
}

.footer--new__menu {
  display: none;
}

.footer--en {
  padding-top: 100px;
}

@media (max-width: 1440px) {
  .footer__closest-office {
    max-width: 360px;
  }

  .footer--en {
    padding-top: 60px;
  }
}

@media (max-width: 680px) {
  .footer--en {
    padding-top: 40px;
  }
}

.footer--en .footer__closest-office {
  max-width: 265px;
}

@media (max-width: 460px) {
  .footer__menu {
    display: none;
  }

  .footer--en .footer__closest-office {
    max-width: 100%;
  }
}

.footer--en .footer__menu {
  display: flex;
}

@media (max-width: 640px) {
  .footer--en .footer__menu {
    flex-wrap: wrap;
  }
}

.footer--en .footer__menu > li {
  flex: 1;
}

@media (max-width: 640px) {
  .footer--en .footer__menu > li {
    flex-basis: 50%;
  }

  .footer--en .footer__menu > li:nth-child(n + 3) {
    margin-top: 30px;
  }
}

.footer--en .footer__menu > li > a {
  border-bottom: none;
  cursor: default;
  color: #07393C;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 5px 0 20px;
}

.footer--en .footer__menu > li + li {
  margin-top: 0;
}

.footer--en .footer__menu .dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer--en .footer__menu .dropdown-menu li {
  margin-right: 20px;
}

.footer--en .footer__menu .dropdown-menu li a {
  font-size: 0.875rem;
}

.footer--en .footer__menu .dropdown-menu li + li {
  margin-top: 0.25rem;
}

.footer--en .social {
  margin-top: 40px;
}

@media (max-width: 960px) {
  .header--new__search {
    align-self: flex-end;
  }

  .footer--en .footer__menu > li > a {
    margin-bottom: 10px;
  }

  .footer--en .social {
    margin-bottom: 40px;
    margin-top: 30px;
  }
}

.scroll-to-top {
  bottom: 2px;
  cursor: pointer;
  outline: 0;
  position: fixed;
  right: 2px;
  transform: translateY(150%);
  transition: transform 0.3s ease;
  z-index: 100;
}

.footer--new__logo,
.footer--new__menu a.dropdown-item {
  position: relative;
  outline: 0;
}

.scroll-to-top__arrow {
  transition: d 0.3s;
}

.scroll-to-top:hover .scroll-to-top__arrow {
  d: path("M32 24L28 20M24 24L28 20M28 20L28 36");
}

.scroll-to-top:focus-visible {
  border-radius: 4px;
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.scroll-to-top--active {
  transform: translateY(0);
}

.footer--new {
  padding-top: 40px;
}

@media (min-width: 768px) {
  .mobile-menu-active .header--new__info {
    top: 65px;
  }

  .footer--new__row {
    display: grid;
    margin-left: 64px;
    margin-right: 64px;
  }
}

@media (min-width: 960px) {
  .footer--new {
    padding-top: 80px;
  }

  .footer--new__row {
    align-items: flex-start;
    grid-template-columns: 1fr 2fr;
    grid-gap: 16px;
    margin-left: 80px;
    margin-right: 80px;
  }
}

@media (min-width: 1280px) {
  .footer--new__row {
    margin-left: 208px;
    margin-right: 0;
  }
}

@media (min-width: 1440px) {
  .footer--new__row {
    grid-template-columns: 208px 1fr;
    margin-left: 0;
  }
}

@media (min-width: 1920px) {
  .footer--new__row {
    grid-template-columns: 288px 1fr;
  }
}

@media (min-width: 2560px) {
  .header__menu--new .about-us .dropdown-menu > .menu-item .menu-title {
    min-height: 64px;
  }

  .footer--new {
    padding-top: 120px;
  }

  .footer--new__row {
    grid-template-columns: 384px 1fr;
  }
}

@media (min-width: 768px) {
  .footer--new__col-1 {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 960px) {
  .footer--new__col-1 {
    grid-gap: 32px;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1440px) {
  .footer--new__col-1 {
    grid-gap: 40px;
  }
}

.footer--new__logo {
  display: inline-block;
  vertical-align: top;
}

.footer--new__logo-wrap {
  margin-bottom: 40px;
}

.footer--new__logo:after {
  border-radius: 20px;
  border: 2px solid #2C666E;
  bottom: -7px;
  content: "";
  left: -11px;
  opacity: 0;
  position: absolute;
  right: -11px;
  top: -4px;
  transition: opacity 0.1s cubic-bezier(0.4, 0.2, 0.4, 1);
}

.footer--new__logo-image {
  height: 24px;
  vertical-align: top;
  width: auto;
}

.footer--new__menu a.dropdown-item:before,
.footer--new__submenu li a:before {
  height: 10px;
  left: 0;
  transform: translateY(-50%);
  width: 10px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L9 1M9 1H1M9 1V9' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) {
  .footer--new__logo-wrap {
    margin-bottom: 0;
  }

  .footer--new__menu {
    display: grid;
    grid-gap: 40px 16px;
    grid-template-columns: 1fr 1fr 1fr;
    list-style: none;
    margin: 40px 0 0;
    padding: 0;
  }
}

.footer--new__menu .title-h4 .nav-link {
  display: block;
  margin-bottom: 24px;
}

.footer--new__menu .submenu-title > .dropdown-item {
  font-weight: 700;
}

.footer--new__menu .dropdown-menu-opener {
  display: none;
}

.footer--new__menu .services {
  grid-column: span 2;
}

@media (min-width: 960px) {
  .footer--new__menu {
    margin-top: 0;
  }

  .footer--new__menu .services {
    grid-column: span 3;
    padding-right: 33.333%;
  }
}

@media (min-width: 1440px) {
  .footer--new__menu {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .footer--new__menu .services {
    grid-column: span 2;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .footer--new__menu .services > .dropdown-menu__wrapper > .dropdown-menu {
    display: grid;
    grid-gap: 24px 16px;
    grid-template-columns: 1fr 1fr;
  }

  .footer--new__menu .dropdown-menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.footer--new__menu .dropdown-item {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-top: 8px;
  text-decoration: none;
  transition: padding 0.3s;
  vertical-align: top;
}

.footer--new__menu a.dropdown-item:before {
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: opacity 0.3s;
}

.footer--new__menu a.dropdown-item:focus-visible,
.footer--new__menu a.dropdown-item:hover {
  padding-left: 16px;
  padding-right: 0;
}

.footer--new__menu a.dropdown-item:focus-visible:before,
.footer--new__menu a.dropdown-item:hover:before {
  opacity: 1;
}

.footer--new__address {
  flex: 1;
  font-size: 12px;
  line-height: 16px;
}

@media (min-width: 1280px) {
  .footer--new__menu .dropdown-item {
    font-size: 14px;
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .footer--new__address {
    font-size: 14px;
    line-height: 24px;
  }
}

.footer--new__address-wrap {
  display: flex;
  gap: 24px;
}

@media (min-width: 768px) {
  .footer--new__address-wrap {
    grid-column: span 2;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  .footer--new__info {
    margin-top: 40px;
  }

  .footer--new__info .footer--new__row {
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
  }

  .footer--new__info
    .footer--new__row:not(:has(.lang--new))
    .footer--new__copyright {
    top: 8px;
  }
}

.footer--new__address-title {
  font-weight: 700;
  margin-bottom: 4px;
}

.footer--new__address-text {
  max-width: 200px;
}

.footer--new__info {
  margin-bottom: 40px;
}

@media (min-width: 960px) {
  .footer--new__address-wrap {
    grid-gap: 32px;
    grid-template-columns: 1fr;
  }

  .footer--new__info {
    margin-bottom: 80px;
    margin-top: 80px;
  }

  .footer--new__info .footer--new__row {
    align-items: center;
  }

  .footer--new__info
    .footer--new__row:not(:has(.lang--new))
    .footer--new__copyright {
    grid-column: span 1;
    padding-left: 0;
    text-align: right;
  }
}

@media (min-width: 1440px) {
  .footer--new__info .footer--new__row {
    grid-template-columns: 208px 1.6fr 1fr 1.5fr;
  }
}

@media (min-width: 1680px) {
  .footer--new__info .footer--new__row {
    grid-template-columns: 208px 1.8fr 1fr 1.75fr;
  }
}

@media (min-width: 1920px) {
  .footer--new__info .footer--new__row {
    grid-template-columns: 288px 1.76fr 1fr 1.7fr;
  }
}

@media (min-width: 2560px) {
  .footer--new__info {
    margin-bottom: 120px;
    margin-top: 120px;
  }

  .footer--new__info .footer--new__row {
    grid-template-columns: 384px 1.96fr 1fr 2fr;
  }
}

.footer--new__copyright {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

@media (min-width: 768px) {
  .footer--new__copyright {
    position: absolute;
    bottom: 8px;
    right: 0;
    text-align: right;
  }
}

@media (min-width: 960px) {
  .footer--new__copyright {
    grid-column: span 3;
    padding-left: calc(33.333% + 5px);
    position: static;
    text-align: left;
  }
}

@media (min-width: 1440px) {
  .footer--new__info .footer--new__row:not(:has(.lang--new)) {
    grid-template-columns: 208px 1fr 1.5fr;
  }

  .footer--new__copyright {
    grid-column: span 1;
    padding-left: 0;
    text-align: right;
  }
}

.footer--new__submenu {
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: center;
  list-style: none;
  margin: 0 0 16px;
  padding: 0;
}

@media (min-width: 768px) {
  .footer--new__submenu {
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
    margin-bottom: 0;
  }
}

@media (min-width: 960px) {
  .footer--new__submenu {
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
  }

  .footer--new__lang .lang--new__items {
    border: none;
    display: flex;
    position: static;
  }
}

@media (min-width: 1280px) {
  .footer--new__copyright {
    font-size: 14px;
    line-height: 24px;
  }

  .footer--new__submenu {
    gap: 24px;
  }
}

@media (min-width: 1920px) {
  .footer--new__info .footer--new__row:not(:has(.lang--new)) {
    grid-template-columns: 288px 1fr 1.5fr;
  }

  .footer--new__submenu {
    gap: 32px;
  }
}

.footer--new__submenu li a {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  outline: 0;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-top: 8px;
  position: relative;
  text-decoration: none;
  transition: padding 0.3s;
  vertical-align: top;
  white-space: nowrap;
}

.footer--new__submenu li a:before {
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: opacity 0.3s;
}

.footer--new__submenu li a:focus-visible,
.footer--new__submenu li a:hover {
  padding-left: 16px;
  padding-right: 0;
}

.footer--new__submenu li a:focus-visible:before,
.footer--new__submenu li a:hover:before {
  opacity: 1;
}

.footer--new__lang {
  align-items: center;
  gap: 24px;
  justify-content: center;
  margin-bottom: 24px;
  padding: 0;
}

@media (min-width: 768px) {
  .footer--new__lang {
    align-self: flex-start;
    gap: 4px;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .footer--new__lang {
    justify-content: flex-start;
  }
}

.footer--new__lang .lang--new__items {
  align-items: center;
  background-color: transparent;
  gap: 24px;
}

@media (min-width: 768px) {
  .footer--new__lang .lang--new__items {
    gap: 4px;
  }
}

.footer--new__lang .lang--new__link {
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
}

.footer--new .social {
  gap: 16px;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .footer--new .social {
    justify-content: flex-start;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.footer--new .social__item {
  margin: 0;
}

.footer--new .social__link {
  height: 40px;
  width: 40px;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter,
#CybotCookiebotDialog #CybotCookiebotDialogHeader,
#CybotCookiebotDialog #CybotCookiebotDialogNav,
#CybotCookiebotDialog
  .CookieCard
  .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
  display: none;
}

@media screen and (min-width: 601px) {
  #CybotCookiebotDialog[name="CybotCookiebotDialog"] {
    max-width: 640px;
  }
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard {
  background-color: #f8f8f9;
  border-bottom: none;
  border-radius: 8px;
  margin: 0;
  padding: 8px;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard:not(:last-child) {
  margin-bottom: 8px;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogDetailBodyContentCookieContainerButton:before,
#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogDetailBodyContentIABv2Tab:before,
#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard.CybotCookiebotDialogCollapsed:before {
  left: 6px;
  margin-right: 20px;
  top: 10px;
  transition: border-color 0.3s;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogDetailBodyContentCookieContainerButton.CybotCookiebotDialogCollapsed:before,
#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogDetailBodyContentIABv2Tab.CybotCookiebotDialogCollapsed:before {
  top: 6px;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  label {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  transition: color 0.3s;
}

@media (min-width: 1280px) {
  .footer--new__submenu li a {
    font-size: 14px;
    line-height: 24px;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard {
    padding: 16px;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    .CybotCookiebotDialogDetailBodyContentCookieContainerButton:before,
  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    .CybotCookiebotDialogDetailBodyContentIABv2Tab:before,
  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard.CybotCookiebotDialogCollapsed:before {
    left: 4px;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    label {
    line-height: 24px;
  }
}

@media (min-width: 1920px) {
  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    label {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (min-width: 2560px) {
  .footer--new__info .footer--new__row:not(:has(.lang--new)) {
    grid-template-columns: 384px 1fr 1.5fr;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    label {
    font-size: 24px;
    line-height: 32px;
  }
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogDetailBulkConsentCount {
  background-color: #e9edf3;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 8px;
  padding: 0 4px;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #e9edf3;
  box-shadow: 0 0 0 2px #54575e inset;
  transition: background-color 0.3s;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogBodyLevelButtonSlider:before {
  background-color: #54575e;
  transform: scale(0.8);
  transition-duration: 0.3s;
  transition-property: background-color, transform;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  input:checked
  + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #2C666E;
  box-shadow: none;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  input:checked
  + .CybotCookiebotDialogBodyLevelButtonSlider:before {
  background-color: #e9edf3;
  transform: scale(1) translateX(26px);
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogBodyLevelButtonSliderWrapperDisabled
  .CybotCookiebotDialogBodyLevelButtonSlider {
  box-shadow: 0 0 0 2px rgba(29, 27, 32, 0.12) inset;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogBodyLevelButtonSliderWrapperDisabled
  .CybotCookiebotDialogBodyLevelButtonSlider:before {
  background-color: rgba(155, 159, 173, 0.38);
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogBodyLevelButtonSliderWrapperDisabled
  input:checked
  + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: rgba(29, 27, 32, 0.12);
  box-shadow: none;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CookieCard
  .CybotCookiebotDialogBodyLevelButtonSliderWrapper:hover
  input[type="checkbox"]:not(:disabled)
  + .CybotCookiebotDialogBodyLevelButtonSlider {
  opacity: 1;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogTabContent
  .CybotCookiebotScrollContainer {
  border-bottom-color: #c7c7d6;
  padding: 8px;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
  padding: 8px;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContent {
  padding: 8px !important;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogBodyContent
  #CybotCookiebotDialogBodyContentTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
}

@media (min-width: 1280px) {
  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    .CybotCookiebotDialogDetailBulkConsentCount {
    font-size: 14px;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 8px;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogTabContent
    .CybotCookiebotScrollContainer {
    padding: 16px !important;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogTabContent
    #CybotCookiebotDialogBody
    .CybotCookiebotScrollContainer {
    max-height: 300px;
  }

  #CybotCookiebotDialog #CybotCookiebotDialogBodyContent {
    padding: 8px 16px !important;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogBodyContent
    #CybotCookiebotDialogBodyContentTitle {
    line-height: 24px;
    margin-bottom: 16px;
  }
}

@media (min-width: 1920px) {
  #CybotCookiebotDialog
    #CybotCookiebotDialogBodyContent
    #CybotCookiebotDialogBodyContentTitle {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (min-width: 2560px) {
  #CybotCookiebotDialog
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
    .CookieCard
    .CybotCookiebotDialogDetailBulkConsentCount {
    font-size: 16px;
    line-height: 24px;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogBodyContent
    #CybotCookiebotDialogBodyContentTitle {
    font-size: 24px;
    line-height: 32px;
  }
}

#CybotCookiebotDialog
  #CybotCookiebotDialogBodyContent
  #CybotCookiebotDialogBodyContentText,
#CybotCookiebotDialog
  #CybotCookiebotDialogBodyContent
  #CybotCookiebotDialogBodyContentText
  * {
  font-size: 12px;
  line-height: 16px;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogBodyContent
  #CybotCookiebotDialogBodyContentText
  a {
  color: inherit;
  font-weight: 400;
  text-decoration: underline;
  transition: text-decoration-color 0.3s;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogBodyContent
  #CybotCookiebotDialogBodyContentText
  a:hover,
#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyButtonDecline:hover,
#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonCustomize:hover,
#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:hover {
  opacity: 1;
  text-decoration-color: transparent;
}

#CybotCookiebotDialog #CybotCookiebotDialogFooter {
  padding: 16px;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyButtonsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyButtonDecline,
#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  border: none;
  color: #54575e;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  transition: text-decoration-color 0.3s;
  width: auto;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyButtonDecline
  .CybotCookiebotDialogArrow,
#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonCustomize
  .CybotCookiebotDialogArrow,
#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection
  .CybotCookiebotDialogArrow {
  display: none;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  text-align: right;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  align-items: center;
  border-radius: 48px;
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  gap: 8px;
  grid-column: span 2;
  justify-content: center;
  line-height: 16px;
  margin: 0;
  order: -1;
  padding: 12px 16px;
  text-align: center;
  transition-duration: 0.3s;
  transition-property: background-color, border-color, color;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

@media (min-width: 601px) {
  #CybotCookiebotDialog
    #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyButtonsWrapper {
    display: flex;
    gap: 16px;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyButtonDecline {
    order: 1;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    margin-right: auto;
  }
}

@media (min-width: 1280px) {
  #CybotCookiebotDialog
    #CybotCookiebotDialogBodyContent
    #CybotCookiebotDialogBodyContentText,
  #CybotCookiebotDialog
    #CybotCookiebotDialogBodyContent
    #CybotCookiebotDialogBodyContentText
    * {
    font-size: 14px;
    line-height: 24px;
  }

  #CybotCookiebotDialog #CybotCookiebotDialogFooter {
    padding: 24px 32px !important;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    font-size: 15px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1920px) {
  #CybotCookiebotDialog
    #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 24px;
  }
}

#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:after {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L9 1M9 1H1M9 1V9' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  filter: brightness(0) invert(1);
  flex: 0 0 10px;
  height: 10px;
  transition: transform 0.3s;
  width: 10px;
}

@media (min-width: 2560px) {
  #CybotCookiebotDialog
    #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    font-size: 20px;
    line-height: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }

  #CybotCookiebotDialog
    #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6569 13.6566L13.6569 2.34291M2.34314 2.34292L13.6569 2.34291M13.6569 2.34291L2.34314 13.6566' stroke='%231E1D28'/%3E%3C/svg%3E%0A");
    flex-basis: 16px;
    height: 16px;
    width: 16px;
  }
}

#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:focus-visible,
#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:hover {
  background-color: #2C666E;
  border-color: #2C666E;
}

#CybotCookiebotDialog
  #CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

iframe,
img,
video {
  max-width: 100%;
}

#pgc-9-0-0 {
  width: 100%;
}

#pl-9 .so-panel,
#pl-9 .so-panel:last-of-type {
  margin-bottom: 0;
}

#pg-9-0.panel-no-style {
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

@media (max-width: 780px) {
  #pg-9-0,
  #pg-9-0.panel-no-style {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  #pg-9-0 > .panel-grid-cell {
    width: 100%;
    margin-right: 0;
  }

  #pl-9 .panel-grid-cell {
    padding: 0;
  }
}

.so-widget-head-home-style-d75171398898-9 .section--head-home {
  scroll-padding-top: 120px;
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home {
    height: 384px;
  }

  .so-widget-head-home-style-d75171398898-9 .section--head-home .title-h1 {
    max-width: 528px;
    opacity: 0;
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home {
    height: 536px;
  }
}

@media (min-width: 1440px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home {
    height: 584px;
  }
}

.so-widget-head-home-style-d75171398898-9 .section--head-home .container-n {
  height: 100%;
}

.so-widget-head-home-style-d75171398898-9 .section--head-home .title-h1 {
  color: #fff;
  margin-bottom: 16px;
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home .title-h1 {
    max-width: 624px;
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home .title-h1 {
    max-width: 820px;
    margin-bottom: 24px;
  }
}

@media (min-width: 1680px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home {
    height: 660px;
  }

  .so-widget-head-home-style-d75171398898-9 .section--head-home .title-h1 {
    max-width: 920px;
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home {
    height: 800px;
  }

  .so-widget-head-home-style-d75171398898-9 .section--head-home .title-h1 {
    max-width: 1060px;
    margin-bottom: 40px;
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home {
    height: 1040px;
  }

  .so-widget-head-home-style-d75171398898-9 .section--head-home .title-h1 {
    margin-bottom: 64px;
    max-width: 1366px;
  }
}

.so-widget-head-home-style-d75171398898-9 .section--head-home .description-n {
  color: #fff;
  margin-bottom: 16px;
  max-width: 480px;
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home .description-n {
    margin-bottom: 8px;
  }

  .so-widget-head-home-style-d75171398898-9 .section--head-home .description-n {
    opacity: 0;
  }
}

.so-widget-head-home-style-d75171398898-9 .section__cards {
  display: flex;
  grid-column: 1;
  grid-row: 1;
  gap: 8px;
  opacity: 0;
  width: 100%;
  max-width: 100vw;
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home .description-n {
    margin-bottom: 16px;
  }

  .so-widget-head-home-style-d75171398898-9 .section__cards {
    gap: 16px;
  }
}

@media (max-width: 767px) {
  .so-widget-head-home-style-d75171398898-9 .section__cards .slick-track {
    display: flex;
    gap: 8px;
    height: 100%;
  }

  .so-widget-head-home-style-d75171398898-9 .section__cards .slick-list {
    margin-left: -16px;
    padding-left: 16px;
  }
}

.so-widget-head-home-style-d75171398898-9 .section__cards.show {
  animation: 0.6s forwards show;
}

.so-widget-head-home-style-d75171398898-9 .section__cards.show ~ .section__cta {
  animation: 0.6s 0.1s forwards show;
}

.so-widget-head-home-style-d75171398898-9 .section__cards-holder {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
}

.so-widget-head-home-style-d75171398898-9 .section__cta {
  background-color: #fff;
  border-radius: 16px;
  display: none;
  gap: 16px;
  opacity: 0;
  padding: 10px;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
  width: 352px;
}

.so-widget-head-home-style-d75171398898-9 .section__cta-video {
  border-radius: 8px;
  flex: 0 0 152px;
  overflow: hidden;
}

@media (min-width: 1680px) {
  .so-widget-head-home-style-d75171398898-9 .section__cta {
    width: 392px;
  }

  .so-widget-head-home-style-d75171398898-9 .section__cta-video {
    flex-basis: 192px;
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home .description-n {
    margin-bottom: 24px;
  }

  .so-widget-head-home-style-d75171398898-9 .section__cta {
    width: 468px;
  }

  .so-widget-head-home-style-d75171398898-9 .section__cta-video {
    flex-basis: 203px;
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9 .section--head-home .description-n {
    max-width: 676px;
    margin-bottom: 32px;
  }

  .so-widget-head-home-style-d75171398898-9 .section__cta {
    width: 610px;
  }

  .so-widget-head-home-style-d75171398898-9 .section__cta-video {
    flex-basis: 264px;
  }
}

.so-widget-head-home-style-d75171398898-9 .section__cta-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
}

.so-widget-head-home-style-d75171398898-9
  .section__cta:focus-visible
  .link-n:before,
.so-widget-head-home-style-d75171398898-9 .section__cta:hover .link-n:before {
  transform: scale(1);
}

.so-widget-head-home-style-d75171398898-9
  .section__cta:focus-visible
  .link-n:after,
.so-widget-head-home-style-d75171398898-9 .section__cta:hover .link-n:after {
  transform: scale(0);
}

.so-widget-head-home-style-d75171398898-9
  .section__cta:focus-visible
  .link-n__text,
.so-widget-head-home-style-d75171398898-9 .section__cta:hover .link-n__text {
  transform: translateX(18px);
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9
    .section__cta:focus-visible
    .link-n__text,
  .so-widget-head-home-style-d75171398898-9 .section__cta:hover .link-n__text {
    transform: translateX(24px);
  }
}

.so-widget-head-home-style-d75171398898-9 .section__cta:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-head-home-style-d75171398898-9
  [data-count="4"][data-id="1"]
  ~ .section__cta {
  transform: translate(-146px, -50%);
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="4"][data-id="1"]
    ~ .section__cta {
    transform: translate(-201px, -50%);
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="4"][data-id="1"]
    ~ .section__cta {
    transform: translate(-265px, -50%);
  }
}

.so-widget-head-home-style-d75171398898-9
  [data-count="4"][data-id="2"]
  ~ .section__cta {
  transform: translate(-34px, -50%);
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="4"][data-id="2"]
    ~ .section__cta {
    transform: translate(-49px, -50%);
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="4"][data-id="2"]
    ~ .section__cta {
    transform: translate(-63px, -50%);
  }
}

.so-widget-head-home-style-d75171398898-9
  [data-count="4"][data-id="3"]
  ~ .section__cta {
  transform: translate(-24px, -50%);
}

.so-widget-head-home-style-d75171398898-9
  [data-count="4"][data-id="4"]
  ~ .section__cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.so-widget-head-home-style-d75171398898-9
  [data-count="5"][data-id="1"]
  ~ .section__cta {
  transform: translate(-294px, -50%);
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="5"][data-id="1"]
    ~ .section__cta {
    transform: translate(-421px, -50%);
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="5"][data-id="1"]
    ~ .section__cta {
    transform: translate(-535px, -50%);
  }
}

.so-widget-head-home-style-d75171398898-9
  [data-count="5"][data-id="2"]
  ~ .section__cta {
  transform: translate(-182px, -50%);
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="5"][data-id="2"]
    ~ .section__cta {
    transform: translate(-269px, -50%);
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="5"][data-id="2"]
    ~ .section__cta {
    transform: translate(-335px, -50%);
  }
}

.so-widget-head-home-style-d75171398898-9
  [data-count="5"][data-id="3"]
  ~ .section__cta {
  transform: translate(-34px, -50%);
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="5"][data-id="3"]
    ~ .section__cta {
    transform: translate(-117px, -50%);
  }

  .so-widget-head-home-style-d75171398898-9 [data-count="5"] ~ .section__cta {
    width: 400px;
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="5"][data-id="3"]
    ~ .section__cta {
    transform: translate(-134px, -50%);
  }
}

.so-widget-head-home-style-d75171398898-9
  [data-count="5"][data-id="4"]
  ~ .section__cta {
  transform: translate(-24px, -50%);
}

.so-widget-head-home-style-d75171398898-9
  [data-count="5"][data-id="5"]
  ~ .section__cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.so-widget-head-home-style-d75171398898-9 [data-count="5"] ~ .section__cta {
  display: none;
}

@media (min-width: 1680px) {
  .so-widget-head-home-style-d75171398898-9 [data-count="5"] ~ .section__cta {
    display: flex;
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9
    [data-count="5"]
    ~ .section__cta-video {
    flex-basis: 176px;
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9 [data-count="5"] ~ .section__cta {
    width: 540px;
  }

  .so-widget-head-home-style-d75171398898-9
    [data-count="5"]
    ~ .section__cta-video {
    flex-basis: 220px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card {
  border-radius: 8px;
  color: #fff;
  flex: 0 0 90vw;
  height: auto;
  overflow: hidden;
  position: relative;
  transition: flex 0.5s cubic-bezier(0.4, 0.2, 0.4, 1);
}

@media (min-width: 375px) {
  .so-widget-head-home-style-d75171398898-9 .card {
    min-height: 496px;
  }
}

@media (min-width: 420px) {
  .so-widget-head-home-style-d75171398898-9 .card {
    min-height: 576px;
  }
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9 .card {
    flex-basis: 52px;
    min-height: initial;
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9 .card {
    flex-basis: 68px;
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card {
    flex-basis: 88px;
  }
}

@media (min-width: 1440px) {
  .so-widget-head-home-style-d75171398898-9 .section__cta {
    display: flex;
  }

  .so-widget-head-home-style-d75171398898-9 .card {
    flex-basis: 96px;
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9 .card {
    flex-basis: 136px;
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9 .card {
    flex-basis: 184px;
  }
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible),
  .so-widget-head-home-style-d75171398898-9 .card:hover {
    flex-basis: 64px;
  }

  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__name,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__name {
    transform: rotate(-90deg) translateY(-8px);
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible),
  .so-widget-head-home-style-d75171398898-9 .card:hover {
    flex-basis: 88px;
  }

  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__name,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__name {
    transform: rotate(-90deg) translateY(-12px);
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible),
  .so-widget-head-home-style-d75171398898-9 .card:hover {
    flex-basis: 120px;
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__name,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__name {
    transform: rotate(-90deg) translateY(-16px);
  }
}

@media (min-width: 1440px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible),
  .so-widget-head-home-style-d75171398898-9 .card:hover {
    flex-basis: 132px;
  }

  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__name,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__name {
    transform: rotate(-90deg) translateY(-18px);
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible),
  .so-widget-head-home-style-d75171398898-9 .card:hover {
    flex-basis: 188px;
  }

  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__name,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__name {
    transform: rotate(-90deg) translateY(-26px);
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible),
  .so-widget-head-home-style-d75171398898-9 .card:hover {
    flex-basis: 256px;
  }

  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__name,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__name {
    transform: rotate(-90deg) translateY(-36px);
  }
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__icon,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__icon {
    transform: translateX(6px);
  }

  .so-widget-head-home-style-d75171398898-9 .card.active {
    flex: 1;
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__icon,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__icon {
    transform: translateX(10px);
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__icon,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__icon {
    transform: translateX(16px);
  }
}

@media (min-width: 1440px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__icon,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__icon {
    transform: translateX(18px);
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__icon,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__icon {
    transform: translateX(26px);
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9
    .card:has(.card__icon:focus-visible)
    .card__icon,
  .so-widget-head-home-style-d75171398898-9 .card:hover .card__icon {
    transform: translateX(36px);
  }
}

.so-widget-head-home-style-d75171398898-9
  .card:has(.card__icon:focus-visible)
  .card__icon-circle,
.so-widget-head-home-style-d75171398898-9 .card:hover .card__icon-circle {
  fill: #fff;
}

.so-widget-head-home-style-d75171398898-9
  .card:has(.card__icon:focus-visible)
  .card__icon-plus,
.so-widget-head-home-style-d75171398898-9 .card:hover .card__icon-plus {
  stroke: #07393C;
}

.so-widget-head-home-style-d75171398898-9 .card:not(.active) {
  cursor: pointer;
}

.so-widget-head-home-style-d75171398898-9 .card.active .title-h1 {
  opacity: 1;
}

.so-widget-head-home-style-d75171398898-9 .card.active .description-n {
  opacity: 1;
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9 .card.active .card__mask {
    backdrop-filter: initial;
    -webkit-backdrop-filter: initial;
  }

  .so-widget-head-home-style-d75171398898-9 .card.active .card__name {
    top: 1px;
    transform: rotate(0) translateX(8px);
  }

  .so-widget-head-home-style-d75171398898-9 .card.active .card__progress-line {
    animation-name: progress1;
    animation-fill-mode: forwards;
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9 .card.active .card__name {
    top: 2px;
    transform: rotate(0) translateX(16px);
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card.active .card__name {
    top: 19px;
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9 .card.active .card__name {
    top: 52px;
    transform: rotate(0) translateX(20px);
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9 .card.active .card__name {
    top: 66px;
    transform: rotate(0) translateX(24px);
  }
}

.so-widget-head-home-style-d75171398898-9 .card.active .card__info {
  opacity: 1;
}

.so-widget-head-home-style-d75171398898-9 .card.active .card__progress {
  opacity: 1;
}

.so-widget-head-home-style-d75171398898-9 .card.active .card__arrows {
  transform: translateX(0);
}

.so-widget-head-home-style-d75171398898-9 .card.active .card__icon {
  visibility: hidden;
  opacity: 0;
}

.so-widget-head-home-style-d75171398898-9 .card__image {
  display: flex;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
}

.so-widget-head-home-style-d75171398898-9 .card__mask {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.so-widget-head-home-style-d75171398898-9 .card__text {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  padding-bottom: 64px;
  padding-right: 16px;
  position: relative;
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9 .card__mask {
    background-color: transparent;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(30, 29, 40, 0.5600000000000001) 100%
    );
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  .so-widget-head-home-style-d75171398898-9 .card__text {
    height: 100%;
    padding-bottom: 16px;
    padding-top: 96px;
    position: absolute;
    width: 62vw;
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9 .card__text {
    padding-top: 60px;
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card__text {
    padding-bottom: 24px;
    padding-top: 104px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__name {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  margin-top: 16px;
  margin-left: 16px;
  padding-left: 26px;
  position: relative;
  white-space: nowrap;
  width: 250px;
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9 .card__name {
    font-size: 14px;
    line-height: 1.43;
    padding-left: 0;
    padding-right: 26px;
    position: absolute;
    right: 24px;
    text-align: right;
    top: -4px;
    transform-origin: right;
    transform: rotate(-90deg);
    transition-duration: 0.5s;
    transition-property: transform, top;
    transition-timing-function: cubic-bezier(0.4, 0.2, 0.4, 1);
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9 .card__name {
    font-size: 15px;
    line-height: 24px;
    right: 34px;
    top: -3px;
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card__name {
    right: 44px;
    top: 14px;
  }
}

@media (min-width: 1440px) {
  .so-widget-head-home-style-d75171398898-9 .card__text {
    padding-bottom: 40px;
    padding-top: 112px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__name {
    right: 48px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__name:after {
  border-radius: 50%;
  border: 1px solid #fff;
  content: "";
  height: 10px;
  left: 0;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 10px;
}

.so-widget-head-home-style-d75171398898-9 .card__btn {
  display: none;
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card__btn {
    display: inline-flex;
  }

  .so-widget-head-home-style-d75171398898-9 .card__btn-link {
    display: none;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__block {
  flex: 1;
}

@media (max-width: 959px) {
  .so-widget-head-home-style-d75171398898-9 .card__logos {
    display: none;
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9 .card__logos {
    display: flex;
    gap: 30px;
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card__logos {
    gap: 40px;
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9 .card__text {
    padding-bottom: 56px;
    padding-top: 152px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__name {
    font-size: 16px;
    line-height: 24px;
    right: 68px;
    top: 47px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__logos {
    gap: 70px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__logo {
  height: 56px;
  width: auto;
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card__logo {
    height: 60px;
    margin-bottom: 30px
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9 .card__text {
    padding-bottom: 80px;
    padding-top: 200px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__name {
    font-size: 20px;
    line-height: 1.6;
    right: 88px;
    top: 61px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__logos {
    gap: 72px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__logo {
    height: 100px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__progress {
  background-color: rgba(199, 199, 214, 0.4);
  border-radius: 2px;
  height: 1px;
  margin-bottom: 8px;
  margin-top: 32px;
  max-width: 420px;
  position: relative;
  transition: opacity 0.3s;
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9 .card__name:after {
    left: auto;
    right: 0;
  }

  .so-widget-head-home-style-d75171398898-9 .card__progress {
    margin-bottom: 19px;
    max-width: 80%;
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9 .card__progress {
    margin-top: 16px;
    margin-bottom: 8px;
    max-width: 420px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__progress-line {
  animation-timing-function: linear;
  animation-duration: 18s;
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: left;
}

.so-widget-head-home-style-d75171398898-9 .card__subtitle {
  margin-bottom: 16px;
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card__progress {
    max-width: 546px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__subtitle {
    margin-bottom: 24px;
  }
}

@media (min-width: 1440px) {
  .so-widget-head-home-style-d75171398898-9 .card__subtitle {
    margin-bottom: 32px;
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9 .card__progress {
    max-width: 744px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__subtitle {
    margin-bottom: 40px;
  }
}

@media (max-width: 959px) {
  .so-widget-head-home-style-d75171398898-9 .card__subtitle {
    display: none;
  }

  .so-widget-head-home-style-d75171398898-9 .card__links {
    display: none;
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9 .card__links {
    display: grid;
    grid-gap: 16px 24px;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 420px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__link {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  display: inline-block;
  margin-left: -7px;
  margin-right: -7px;
  padding-right: 16px;
  position: relative;
  text-decoration: none;
  transition: padding 0.3s;
  white-space: nowrap;
}

.so-widget-head-home-style-d75171398898-9 .card__link:before {
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 17L16 9M8 1L16 9M16 9L-6.99382e-07 9' stroke='%23ffffff'/%3E%3C/svg%3E");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  height: 10px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: opacity 0.3s;
  transform: translateY(-50%);
  width: 10px;
}

.so-widget-head-home-style-d75171398898-9 .card__link:focus-visible,
.so-widget-head-home-style-d75171398898-9 .card__link:hover {
  padding-left: 16px;
  padding-right: 0;
}

.so-widget-head-home-style-d75171398898-9 .card__link:focus-visible:before,
.so-widget-head-home-style-d75171398898-9 .card__link:hover:before {
  opacity: 1;
}

.so-widget-head-home-style-d75171398898-9 .card__link:focus-visible {
  border-radius: 20px;
  outline-offset: 4px;
  outline: #2C666E solid 2px;
}

.so-widget-head-home-style-d75171398898-9 .card__arrows {
  display: none;
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9 .card__arrows {
    bottom: 16px;
    display: flex;
    gap: 16px;
    position: absolute;
    right: 16px;
    transform: translateX(70vw);
    transition: transform 0.3s;
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card__links {
    max-width: 546px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__arrows {
    bottom: 28px;
    right: 28px;
  }
}

@media (min-width: 1440px) {
  .so-widget-head-home-style-d75171398898-9 .card__link {
    padding-right: 24px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__link:before {
    height: 18px;
    width: 17px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__link:focus-visible,
  .so-widget-head-home-style-d75171398898-9 .card__link:hover {
    padding-left: 24px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__arrows {
    bottom: 32px;
    right: 32px;
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9 .card__links {
    max-width: 744px;
    grid-column-gap: 32px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__arrows {
    bottom: 48px;
    right: 48px;
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9 .card__subtitle {
    margin-bottom: 64px;
  }

  .so-widget-head-home-style-d75171398898-9 .card__arrows {
    bottom: 64px;
    right: 64px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__arrows--mobile {
  bottom: 16px;
  display: flex;
  gap: 16px;
  left: 16px;
  position: absolute;
}

.so-widget-head-home-style-d75171398898-9 .card__arrow {
  height: 40px;
  transition: opacity 0.3s;
  vertical-align: top;
  width: auto;
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9 .card__arrow {
    height: 56px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__arrow.slick-disabled {
  opacity: 0.24;
}

.so-widget-head-home-style-d75171398898-9 .card__arrow-circle {
  transition: fill 0.3s;
}

.so-widget-head-home-style-d75171398898-9 .card__arrow-icon {
  transition: stroke 0.3s;
}

.so-widget-head-home-style-d75171398898-9 .card__arrow:hover {
  cursor: pointer;
}

.so-widget-head-home-style-d75171398898-9
  .card__arrow:hover
  .card__arrow-circle {
  fill: #fff;
}

.so-widget-head-home-style-d75171398898-9 .card__arrow:hover .card__arrow-icon {
  stroke: #07393C;
}

.so-widget-head-home-style-d75171398898-9 .card__icon {
  display: none;
  height: 40px;
  width: auto;
  position: absolute;
  transition-duration: 0.5s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0.4, 1);
}

@media (min-width: 768px) {
  .so-widget-head-home-style-d75171398898-9 .card__arrows--mobile {
    display: none;
  }

  .so-widget-head-home-style-d75171398898-9 .card__icon {
    bottom: 16px;
    display: block;
    left: 6px;
  }
}

@media (min-width: 960px) {
  .so-widget-head-home-style-d75171398898-9 .card__icon {
    left: 14px;
  }
}

@media (min-width: 1280px) {
  .so-widget-head-home-style-d75171398898-9 .card__icon {
    bottom: 28px;
    left: 24px;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__container {
    min-width: 0;
    padding-right: 0;
  }
}

@media (min-width: 1440px) {
  .so-widget-head-home-style-d75171398898-9 .card__icon {
    bottom: 32px;
    left: 28px;
  }
}

@media (min-width: 1920px) {
  .so-widget-head-home-style-d75171398898-9 .card__icon {
    bottom: 48px;
    left: 48px;
  }
}

@media (min-width: 2560px) {
  .so-widget-head-home-style-d75171398898-9 .card__icon {
    bottom: 64px;
    height: 56px;
    left: 64px;
  }
}

.so-widget-head-home-style-d75171398898-9 .card__icon-circle {
  transition: fill 0.3s;
}

.so-widget-head-home-style-d75171398898-9 .card__icon-plus {
  transition: stroke 0.3s;
}

.so-widget-head-home-style-d75171398898-9 .card__icon:focus-visible {
  border-radius: 50%;
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-head-home-style-d75171398898-9
  .no-js
  .section__cards[data-region="default"] {
  opacity: 1;
}

@keyframes progress1 {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .so-widget-head-home-style-d75171398898-9
    .card.slick-current
    .card__progress-line {
    animation-name: progress1;
    animation-fill-mode: forwards;
  }

  .so-widget-services-picker-2-style-d75171398898-9 .pl-big {
    padding-left: 0;
  }
}

@media (min-width: 1280px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__row {
    align-items: center;
    display: flex;
    gap: 16px;
    position: relative;
  }
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__block {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;
  z-index: 1;
}

@media (min-width: 1280px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__block {
    box-shadow: 0 16px 90px 0 rgba(2, 16, 44, 0.12),
      0 0 2px 0 rgba(2, 16, 44, 0.08);
    padding: 16px;
    flex: 0 0 430px;
  }
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__block {
    flex-basis: 480px;
  }
}

@media (min-width: 1600px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__block {
    flex-basis: 560px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__questions {
  display: flex;
  flex-direction: column;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__question {
  border-bottom: 1px solid #c7c7d6;
  border-left: 1px solid #c7c7d6;
  border-right: 1px solid #c7c7d6;
  position: relative;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question-title {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  outline: 0;
  padding: 8px 16px;
  transition: opacity 0.3s;
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__question-title {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question-title-number,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question-title-text {
  padding-bottom: 8px;
  padding-top: 8px;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question-title-icon {
  flex: 0 0 40px;
  height: 40px;
  margin-left: auto;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question-title-icon-plus {
  transform-origin: center;
  transform: rotate(90deg);
  transition: transform 0.3s;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question-title:focus-visible
  .services-picker__question-title-icon {
  border-radius: 20px;
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question-list {
  padding: 0 16px 16px;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question-info {
  min-height: 73px;
}

@media (min-width: 1280px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__question-info {
    min-height: 235px;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__question-hint {
    white-space: nowrap;
  }
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__question-info {
    min-height: 270px;
  }
}

@media (min-width: 1600px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__question-info {
    min-height: 294px;
  }
}

@media (min-width: 1920px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__question-info {
    min-height: 286px;
  }
}

@media (min-width: 2560px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__question-title-icon {
    flex-basis: 56px;
    height: 56px;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__question-info {
    min-height: 328px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question-hint {
  background-color: #2C666E;
  border-radius: 24px;
  color: #fff;
  display: none;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.71;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 1px solid #c7c7d6;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question--disable
  .services-picker__question-title {
  opacity: 0.4;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question--disable
  .services-picker__question-title-icon {
  opacity: 0;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question--closed
  .services-picker__question-title {
  cursor: pointer;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__question--closed
  .services-picker__question-title-icon-plus {
  transform: rotate(0);
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__tag {
  align-items: center;
  border-radius: 48px;
  border: 1px solid #c7c7d6;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  padding: 5px 12px;
  text-decoration: none;
  transition-duration: 0.3s;
  transition-property: border-color, background-color;
}

@media (min-width: 1280px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__tag {
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__controls {
    margin-bottom: 16px;
    margin-right: 24px;
    min-height: 32px;
  }
}

@media (min-width: 1920px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__tag {
    padding: 7px 15px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__tag:focus-visible,
.so-widget-services-picker-2-style-d75171398898-9 .services-picker__tag:hover {
  background-color: #c7c7d6;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__tag:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__tag--active {
  background-color: #07393C;
  border-color: #07393C;
  color: #fff;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__tag--active:hover {
  background-color: #07393C;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__controls {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-top: 16px;
  min-width: 0;
  position: relative;
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__controls {
    margin-right: 56px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__arrows {
  align-items: center;
  display: flex;
  gap: 16px;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__arrow {
  height: 40px;
  transition: opacity 0.3s;
  vertical-align: top;
  width: auto;
}

@media (min-width: 2560px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__tag {
    padding-left: 24px;
    padding-right: 24px;
  }

  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__controls {
    margin-right: 88px;
  }

  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__arrow {
    height: 56px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__arrow-circle {
  transition: fill 0.3s;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__arrow-icon {
  transition: stroke 0.3s;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__arrow.slick-disabled {
  opacity: 0.24;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__arrow:not(.slick-disabled):hover {
  cursor: pointer;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__arrow:not(.slick-disabled):hover
  .services-picker__arrow-circle {
  fill: #07393C;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__arrow:not(.slick-disabled):hover
  .services-picker__arrow-icon {
  stroke: #fff;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__slider {
  display: flex;
  gap: 8px;
}

@media (min-width: 1280px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__slider {
    max-width: calc(100vw - 470px);
    gap: 16px;
  }
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__slider {
    max-width: calc(100vw - 552px);
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider-wrap {
  min-width: 0;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider
  .slick-list {
  margin: -90px -16px;
  padding: 90px 16px;
}

@media (min-width: 640px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__slider
    .slick-list {
    margin-left: -50px;
    margin-right: -50px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider
  .slick-track {
  display: flex;
  gap: 8px;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.show-slider {
  animation: 0.5s ease-in forwards show-slider;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-1-active
  .services-picker__item:first-child
  .services-picker__item-category,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-1-active
  .services-picker__item:nth-child(2)
  .services-picker__item-category,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-1-active
  .services-picker__item:nth-child(3)
  .services-picker__item-category {
  display: none;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-1-active
  .services-picker__item:first-child
  .services-picker__item-category--recomended,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-1-active
  .services-picker__item:nth-child(2)
  .services-picker__item-category--recomended,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-1-active
  .services-picker__item:nth-child(3)
  .services-picker__item-category--recomended {
  display: inline-flex;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-2-active
  .services-picker__item:first-child
  .services-picker__item-category,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-2-active
  .services-picker__item:nth-child(2)
  .services-picker__item-category {
  display: none;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-2-active
  .services-picker__item:first-child
  .services-picker__item-category--recomended,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-2-active
  .services-picker__item:nth-child(2)
  .services-picker__item-category--recomended {
  display: inline-flex;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-3-active
  .services-picker__item:first-child
  .services-picker__item-category,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-3-active
  .services-picker__item:nth-child(2)
  .services-picker__item-category {
  display: none;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-3-active
  .services-picker__item:first-child
  .services-picker__item-category--recomended,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__slider.question-3-active
  .services-picker__item:nth-child(2)
  .services-picker__item-category--recomended {
  display: inline-flex;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__item {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid transparent;
  flex: 0 0 290px;
  padding: 16px;
  position: relative;
  text-decoration: none;
  transition-duration: 0.3s;
  transition-property: background-color, border-color;
}

@media (min-width: 1600px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__slider {
    max-width: calc(100vw - 632px);
  }

  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__item {
    flex-basis: 416px;
  }
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__item {
    flex-basis: 360px;
  }
}

@media (min-width: 2560px) {
  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__item {
    flex-basis: 460px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:before {
  border-radius: 8px;
  bottom: 0;
  box-shadow: 0 16px 90px 0 rgba(2, 16, 44, 0.12),
    0 0 2px 0 rgba(2, 16, 44, 0.08);
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible,
.so-widget-services-picker-2-style-d75171398898-9 .services-picker__item:hover {
  background-color: #07393C;
  border-color: #07393C;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-description,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-title,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-description,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-title {
  color: #fff;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-category,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-category {
  color: #fff;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-category:before,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-category:before {
  border-color: #fff;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-category--recomended,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-category--recomended {
  background-color: #fff;
  border-color: #fff;
  color: #07393C;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-category--recomended:before,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-category--recomended:before {
  border-color: #07393C;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-image,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-mask,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-view,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-image,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-mask,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-view {
  opacity: 1;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-view,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-view {
  transition-delay: 0.2s;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-link,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-link {
  border-color: #07393C;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-icon,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-icon {
  left: calc(90% - 40px);
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-icon-circle,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-icon-circle {
  fill: transparent;
  stroke: #fff;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible
  .services-picker__item-icon-arrow,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:hover
  .services-picker__item-icon-arrow {
  stroke: #fff;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item-category {
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  transition: color 0.3s;
}

@media (min-width: 1280px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__slider
    .slick-list {
    margin: -90px -16px;
    padding: 90px 16px;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__slider
    .slick-list {
    margin-right: -1500px;
    padding-right: 1500px;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__slider
    .slick-track {
    gap: 16px;
  }

  .so-widget-services-picker-2-style-d75171398898-9 .services-picker__item {
    border-color: #c7c7d6;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item:before {
    display: none;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-category {
    margin-bottom: 78px;
  }
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-category {
    margin-bottom: 90px;
  }
}

@media (min-width: 1920px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-category {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media (min-width: 2560px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-category {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item-category:before {
  border-radius: 50%;
  border: 1px solid #07393C;
  content: "";
  height: 10px;
  transition: border-color 0.3s;
  width: 10px;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item-category--recomended {
  align-items: center;
  background-color: #07393C;
  border-radius: 24px;
  border: 1px solid #07393C;
  color: #fff;
  display: none;
  gap: 16px;
  margin-bottom: 16px;
  padding: 5px 12px;
  transition-duration: 0.3s;
  transition-property: background-color, color;
}

@media (min-width: 1280px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-category--recomended {
    margin-bottom: 78px;
    padding-bottom: 3px;
    padding-top: 3px;
  }
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-category--recomended {
    margin-bottom: 90px;
  }
}

@media (min-width: 1920px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-category--recomended {
    padding: 7px 15px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item-category--recomended:before {
  border-radius: 50%;
  border: 1px solid #fff;
  content: "";
  height: 10px;
  transition: border-color 0.3s;
  width: 10px;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__item-title {
  align-items: flex-end;
  display: flex;
  margin-bottom: 8px;
  min-height: 64px;
  transition: color 0.3s;
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-title {
    min-height: 80px;
  }
}

@media (min-width: 2560px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-category--recomended {
    padding-left: 24px;
    padding-right: 24px;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-title {
    min-height: 96px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item-description {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  color: #54575e;
  display: -webkit-box;
  margin-bottom: 32px;
  min-height: 80px;
  overflow: hidden;
  transition: color 0.3s;
  visibility: visible;
}

@media (min-width: 1280px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-description {
    min-height: 96px;
    margin-bottom: 24px;
  }
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-description {
    margin-bottom: 32px;
  }
}

@media (min-width: 1920px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-description {
    margin-bottom: 48px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__item-link {
  align-items: center;
  border-radius: 64px;
  border: 1px solid #c7c7d6;
  display: flex;
  height: 80px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: border-color 0.3s;
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-link {
    height: 120px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__item-image {
  height: 100%;
  object-fit: cover;
  opacity: 0;
  position: relative;
  transition: opacity 0.3s;
  width: 100%;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__item-mask {
  background-color: rgba(0, 0, 0, 0.24);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__item-view {
  color: #fff;
  font-weight: 700;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__item-line {
  background-color: #c7c7d6;
  height: 1px;
  position: absolute;
  width: 74%;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__item-icon {
  height: 40px;
  left: 10%;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  transition: left 0.6s;
  width: 40px;
}

@media (min-width: 2560px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-description {
    margin-bottom: 62px;
    min-height: 128px;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__item-icon {
    margin-top: -28px;
    height: 56px;
    width: 56px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item-icon-circle {
  fill: #fff;
  transition-duration: 0.3s;
  transition-property: fill, stroke;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item-icon-arrow {
  transition: stroke 0.3s;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item--contact {
  background-color: #2C666E;
  color: #fff;
  text-align: center;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item--contact
  .services-picker__item-category:before {
  display: none;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item--contact
  .services-picker__item-title {
  justify-content: center;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item--contact
  .services-picker__item-description {
  color: #fff;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__item--contact
  .services-picker__item-link {
  border-color: transparent;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__clear {
  align-items: center;
  display: none;
  min-width: 0;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__clear--show {
  display: flex;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__clear-tags {
  display: none;
}

@media (min-width: 1280px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__clear-tags {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    min-width: 0;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__clear-tags
  .services-picker__tag {
  cursor: initial;
  display: inline-block;
  overflow: hidden;
  visibility: visible;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__clear-tags
  .services-picker__tag:hover {
  background-color: inherit;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__clear-btn {
  align-items: center;
  color: #07393C;
  cursor: pointer;
  display: inline-flex;
  gap: 8px;
  outline: 0;
  padding: 4px 12px;
  transition: color 0.3s;
  white-space: nowrap;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__clear-btn:focus-visible,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__clear-btn:hover {
  color: #2C666E;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__clear-btn:focus-visible
  .services-picker__clear-icon-path,
.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__clear-btn:hover
  .services-picker__clear-icon-path {
  fill: #2C666E;
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__clear-btn:focus-visible {
  border-radius: 20px;
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-services-picker-2-style-d75171398898-9 .services-picker__clear-icon {
  height: 10px;
  width: 10px;
}

@media (min-width: 1440px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__clear-btn {
    padding-left: 16px;
    padding-right: 16px;
  }

  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__clear-icon {
    height: 12px;
    width: 12px;
  }
}

.so-widget-services-picker-2-style-d75171398898-9
  .services-picker__clear-icon-path {
  fill: #54575e;
  transition: fill 0.3s;
}

@keyframes show-slider {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__slider {
  display: flex;
  gap: 8px;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__slider
  .slick-list {
  padding-left: 16px;
  padding-right: 16px;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__item {
  flex: 0 0 90vw;
  transition: all 240ms cubic-bezier(0.4, 0.2, 0.4, 1);
}

@media (min-width: 768px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__slider {
    gap: 16px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item {
    flex-basis: 112px;
  }
}

@media (min-width: 960px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item {
    flex-basis: 144px;
  }
}

@media (min-width: 1280px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item {
    flex-basis: 192px;
  }
}

@media (min-width: 1440px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item {
    flex-basis: 208px;
  }
}

@media (min-width: 1920px) {
  .so-widget-services-picker-2-style-d75171398898-9
    .services-picker__clear-icon {
    height: 16px;
    width: 16px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item {
    flex-basis: 288px;
  }
}

@media (min-width: 2560px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item {
    flex-basis: 384px;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__item.active
  .customers-stories__play {
  opacity: 1;
}

@media (min-width: 768px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item.active {
    flex: 1;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item.active
    .customers-stories__mask {
    backdrop-filter: initial;
    -webkit-backdrop-filter: initial;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item.active
    .customers-stories__highlight {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    transition-duration: 0.3s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.4, 0.2, 0.4, 1);
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item.active
    .customers-stories__title {
    display: block;
    animation: 240ms cubic-bezier(0.4, 0.2, 0.4, 1) 240ms forwards show-content;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item.active
    .customers-stories__url {
    display: inline-flex;
    animation: 240ms cubic-bezier(0.4, 0.2, 0.4, 1) 340ms forwards show-content;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item.active
    .customers-stories__video-wrap
    .video {
    animation: 240ms cubic-bezier(0.4, 0.2, 0.4, 1) forwards show-play-btn;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__item.active
  .customers-stories__icon {
  visibility: hidden;
  opacity: 0;
}

@media (max-width: 767px) {
  .so-widget-customer-stories-video-style-d75171398898-9 .pl-big {
    padding-left: 0;
  }

  .so-widget-customer-stories-video-style-d75171398898-9 .customers-stories {
    margin-left: -16px;
    margin-right: -16px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__slider {
    padding-left: 16px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__slider {
    margin-left: -4px;
    margin-right: -4px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__slider.slick-initialized {
    padding-left: 0;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item {
    padding-left: 4px;
    padding-right: 4px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item.slick-current
    .customers-stories__highlight {
    opacity: 1;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item.slick-current
    .customers-stories__video-wrap
    .video {
    animation: 240ms cubic-bezier(0.4, 0.2, 0.4, 1) forwards show-play-btn;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__item:not(.slick-current)
    .customers-stories__row {
    opacity: 0;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__video {
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 0;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__video-wrap {
  border-radius: 8px;
  height: 428px;
  overflow: hidden;
  position: relative;
}

@media (min-width: 768px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__video-wrap {
    height: 224px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__mask {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

@media (min-width: 960px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__video-wrap {
    height: 288px;
  }
}

@media (min-width: 1280px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__video-wrap {
    height: 376px;
  }
}

@media (min-width: 1440px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__video-wrap {
    height: 378px;
  }
}

@media (min-width: 1680px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__video-wrap {
    height: 440px;
  }
}

@media (min-width: 1920px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__video-wrap {
    height: 504px;
  }
}

@media (min-width: 2560px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__video-wrap {
    height: 664px;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__video-wrap
  .video {
  visibility: hidden;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__mask {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.24)
  );
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__play {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5600000000000001);
  border-radius: 32px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  gap: 5px;
  height: auto;
  left: 16px;
  line-height: 16px;
  padding: 5px 10px;
  position: absolute;
  top: 16px;
  transition-duration: 240ms;
  transition-property: background-color, opacity;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0.4, 1);
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__play:focus-visible,
.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__play:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__play:focus-visible {
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__play-icon {
  height: 10px;
  width: auto;
}

@media (min-width: 960px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__play {
    gap: 6px;
    padding: 8px 12px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__play-icon {
    height: 12px;
  }
}

@media (min-width: 1920px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__play {
    font-size: 16px;
    gap: 8px;
    line-height: 24px;
    padding: 8px 16px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__play-icon {
    height: 16px;
  }
}

@media (min-width: 2560px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__play {
    font-size: 20px;
    gap: 12px;
    line-height: 32px;
    padding: 12px 24px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__play-icon {
    height: 22px;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__highlight {
  bottom: 16px;
  color: #fff;
  left: 0;
  margin: 0;
  opacity: 0;
  padding-left: 16px;
  position: absolute;
  right: 16px;
}

@media (min-width: 1280px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__highlight {
    bottom: 32px;
    padding-left: 32px;
  }
}

@media (min-width: 1440px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__highlight {
    right: 32px;
  }
}

@media (min-width: 1920px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__highlight {
    bottom: 40px;
    padding-left: 40px;
    right: 40px;
  }
}

@media (min-width: 2560px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__highlight {
    bottom: 48px;
    padding-left: 198px;
    right: 48px;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9 .customers-stories__row {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  height: 120px;
  margin-top: 16px;
  transition: opacity 240ms cubic-bezier(0.4, 0.2, 0.4, 1);
}

@media (min-width: 768px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__play {
    opacity: 0;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__highlight {
    transform: translateY(12px);
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__row {
    align-items: flex-start;
    gap: 16px;
    height: 116px;
    margin-top: 8px;
  }
}

@media (min-width: 960px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__row {
    margin-top: 16px;
  }
}

@media (min-width: 1280px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__row {
    align-items: center;
    flex-wrap: nowrap;
    gap: 60px;
    height: 72px;
    padding-right: 24px;
  }
}

@media (min-width: 1440px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__row {
    height: 80px;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__logo {
  height: 80px;
  width: auto;
}

@media (min-width: 768px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__logo {
    height: 56px;
  }
}

@media (min-width: 1440px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__logo {
    height: 80px;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__logo-wrap {
  flex: 1;
  height: 80px;
  order: -1;
}

@media (min-width: 768px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__logo-wrap {
    flex: 0 0 100%;
    height: 56px;
  }
}

@media (min-width: 1280px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__logo-wrap {
    flex: 0 0 auto;
  }
}

@media (min-width: 1440px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__logo-wrap {
    height: 80px;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__title {
  flex: 0 0 100%;
  margin-top: -8px;
  max-width: 280px;
}

@media (min-width: 768px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__title {
    display: none;
    margin-top: -16px;
    max-width: 210px;
    opacity: 0;
  }
}

@media (min-width: 960px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__title {
    max-width: 280px;
  }
}

@media (min-width: 1280px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__title {
    flex: 1;
    margin-top: 0;
    max-width: initial;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9 .customers-stories__url {
  margin-left: 10%;
  order: -1;
  white-space: nowrap;
}

@media (min-width: 420px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__url {
    margin-left: 30%;
  }
}

@media (min-width: 560px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__url {
    margin-left: 40%;
  }
}

@media (min-width: 640px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__url {
    margin-left: 50%;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__icon {
  bottom: 32px;
  height: 40px;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 240ms cubic-bezier(0.4, 0.2, 0.4, 1);
  vertical-align: top;
  width: auto;
}

@media (min-width: 768px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__url {
    display: none;
    margin-left: 0;
    margin-top: -16px;
    opacity: 0;
    order: 1;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__icon {
    opacity: 1;
  }
}

@media (min-width: 960px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__icon {
    bottom: 48px;
  }
}

@media (min-width: 1280px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__url {
    margin-top: 0;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__icon {
    bottom: 56px;
  }
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__icon-circle {
  transition: fill 0.3s;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__icon-plus {
  transition: stroke 0.3s;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__icon:focus-visible {
  border-radius: 50%;
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__icon:focus-visible
  .customers-stories__icon-circle {
  fill: #fff;
}

.so-widget-customer-stories-video-style-d75171398898-9
  .customers-stories__icon:focus-visible
  .customers-stories__icon-plus {
  stroke: #07393C;
}

.so-widget-customer-stories-video-style-d75171398898-9 .customers-stories__all {
  margin-top: 32px;
}

@media (min-width: 1280px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__all {
    margin-top: 48px;
  }
}

@media (min-width: 1440px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__all {
    margin-top: 56px;
  }
}

@media (min-width: 1920px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__row {
    gap: 80px;
    padding-right: 48px;
  }

  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__all {
    margin-top: 64px;
  }
}

@keyframes show-content {
  from {
    opacity: 0;
    transform: translateY(12px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-play-btn {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  10% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.so-widget-company-highlights-section-style-d75171398898-9
  .section--company-highlights {
  color: #fff;
  padding-bottom: 0;
}

.so-widget-company-highlights-section-style-d75171398898-9 .section__row {
  margin-left: -16px;
  margin-right: -16px;
  padding-bottom: 24px;
  padding-right: 16px;
}

@media (min-width: 768px) {
  .so-widget-company-highlights-section-style-d75171398898-9 .section__row {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1.2fr;
    grid-gap: 16px;
    padding-bottom: 32px;
  }
}

@media (min-width: 960px) {
  .so-widget-company-highlights-section-style-d75171398898-9 .section__row {
    margin-left: -8px;
    margin-right: -8px;
    padding-right: 72px;
  }
}

@media (min-width: 1280px) {
  .so-widget-company-highlights-section-style-d75171398898-9 .section__row {
    margin-left: 0;
    margin-right: 0;
    padding-right: 208px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .section__title {
    max-width: 400px;
  }
}

@media (min-width: 1440px) {
  .so-widget-company-highlights-section-style-d75171398898-9 .section__row {
    padding-right: 224px;
  }
}

@media (min-width: 1920px) {
  .so-widget-company-highlights-section-style-d75171398898-9 .section__row {
    padding-bottom: 120px;
    padding-right: 304px;
    grid-gap: 80px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .section__title {
    max-width: 500px;
  }
}

.so-widget-company-highlights-section-style-d75171398898-9 .section__awards {
  display: flex;
  padding-bottom: 24px;
  padding-top: 24px;
}

.so-widget-company-highlights-section-style-d75171398898-9
  .section__awards
  .logo {
  height: 28px;
  width: 63px;
}

@media (min-width: 960px) {
  .so-widget-company-highlights-section-style-d75171398898-9 .section__awards {
    padding-bottom: 48px;
    padding-top: 48px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9
    .section__awards
    .logo {
    height: 48px;
    width: 108px;
  }
}

@media (min-width: 1920px) {
  .so-widget-company-highlights-section-style-d75171398898-9 .section__awards {
    padding-bottom: 64px;
    padding-top: 64px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9
    .section__awards
    .logo {
    height: 72px;
    width: 162px;
  }
}

@media (min-width: 2560px) {
  .so-widget-customer-stories-video-style-d75171398898-9
    .customers-stories__icon {
    bottom: 64px;
    height: 56px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .section__row {
    padding-right: 400px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .section__awards {
    padding-bottom: 56px;
    padding-top: 56px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .section__awards {
    margin-left: auto;
    margin-right: auto;
    max-width: 3000px;
    padding-left: 100px;
    padding-top: 86px;
    padding-bottom: 86px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9
    .section__awards
    .logo {
    opacity: 0;
    height: 88px;
    transition: opacity 2.5s linear;
    width: 198px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9
    .section__awards
    .slick-current
    .logo {
    opacity: 1;
  }

  .so-widget-company-highlights-section-style-d75171398898-9
    .section__awards
    .slick-current
    ~ .slick-slide
    .logo {
    opacity: 1;
  }

  .so-widget-company-highlights-section-style-d75171398898-9
    .section__awards
    .slick-current
    + .slick-slide
    + .slick-slide
    + .slick-slide
    + .slick-slide
    + .slick-slide
    + .slick-slide
    ~ .slick-slide
    .logo {
    opacity: 0;
  }
}

.so-widget-company-highlights-section-style-d75171398898-9
  .section__awards
  .slick-track {
  align-items: center;
  display: flex;
}

.so-widget-company-highlights-section-style-d75171398898-9
  .section__award-item {
  flex: 0 0 111px;
  padding-left: 24px;
  padding-right: 24px;
}

@media (min-width: 960px) {
  .so-widget-company-highlights-section-style-d75171398898-9
    .section__award-item {
    flex: 0 0 204px;
    padding-right: 48px;
    padding-left: 48px;
  }
}

.so-widget-company-highlights-section-style-d75171398898-9 .counter {
  display: grid;
  grid-gap: 32px 16px;
  grid-template-columns: 1fr 1fr;
}

@media (min-width: 768px) {
  .so-widget-company-highlights-section-style-d75171398898-9 .counter {
    border-top: 1px solid #54575e;
    grid-row-gap: 48px;
    padding-top: 56px;
  }

  .so-widget-industries-section-style-d75171398898-9 .section__arrows {
    transform: translateY(-56px);
  }
}

.so-widget-company-highlights-section-style-d75171398898-9 .counter__title {
  font-size: 32px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 8px;
}

@media (min-width: 1280px) {
  .so-widget-company-highlights-section-style-d75171398898-9 .counter {
    grid-row-gap: 36px;
    padding-top: 48px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .counter__title {
    font-size: 56px;
    line-height: 56px;
    margin-bottom: 4px;
  }
}

@media (min-width: 1920px) {
  .so-widget-company-highlights-section-style-d75171398898-9
    .section__award-item {
    flex: 0 0 318px;
    padding-left: 78px;
    padding-right: 78px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .counter {
    padding-top: 72px;
    grid-row-gap: 56px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .counter__title {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (min-width: 2560px) {
  .so-widget-company-highlights-section-style-d75171398898-9
    .section__award-item {
    flex: 0 0 368px;
    padding-left: 85px;
    padding-right: 85px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .counter {
    grid-row-gap: 68px;
  }

  .so-widget-company-highlights-section-style-d75171398898-9 .counter__title {
    font-size: 88px;
    line-height: 96px;
  }
}

.so-widget-company-highlights-section-style-d75171398898-9
  .counter__description {
  font-size: 14px;
  line-height: 16px;
}

.so-widget-industries-section-style-d75171398898-9 .section-n {
  background-color: #f8f8f9;
  position: relative;
}

.so-widget-industries-section-style-d75171398898-9 .section__row {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.so-widget-industries-section-style-d75171398898-9 .section__arrows {
  align-items: center;
  display: flex;
  gap: 16px;
}

@media (min-width: 1280px) {
  .so-widget-company-highlights-section-style-d75171398898-9
    .counter__description {
    font-size: 15px;
    line-height: 24px;
  }

  .so-widget-industries-section-style-d75171398898-9 .section__arrows {
    transform: translateY(-64px);
  }
}

@media (min-width: 1920px) {
  .so-widget-company-highlights-section-style-d75171398898-9
    .counter__description {
    font-size: 16px;
  }

  .so-widget-industries-section-style-d75171398898-9 .section__arrows {
    transform: translateY(-80px);
  }
}

.so-widget-industries-section-style-d75171398898-9 .section__arrow {
  height: 40px;
  transition: opacity 0.3s;
  vertical-align: top;
  width: auto;
}

@media (min-width: 2560px) {
  .so-widget-company-highlights-section-style-d75171398898-9
    .counter__description {
    font-size: 20px;
    line-height: 32px;
  }

  .so-widget-industries-section-style-d75171398898-9 .section__arrows {
    transform: translateY(-120px);
  }

  .so-widget-industries-section-style-d75171398898-9 .section__arrow {
    height: 56px;
  }
}

.so-widget-industries-section-style-d75171398898-9 .section__arrow-circle {
  transition: fill 0.3s;
}

.so-widget-industries-section-style-d75171398898-9 .section__arrow-icon {
  transition: stroke 0.3s;
}

.so-widget-industries-section-style-d75171398898-9
  .section__arrow.slick-disabled {
  opacity: 0.24;
}

.so-widget-industries-section-style-d75171398898-9
  .section__arrow:not(.slick-disabled):hover {
  cursor: pointer;
}

.so-widget-industries-section-style-d75171398898-9
  .section__arrow:not(.slick-disabled):hover
  .section__arrow-circle {
  fill: #07393C;
}

.so-widget-industries-section-style-d75171398898-9
  .section__arrow:not(.slick-disabled):hover
  .section__arrow-icon {
  stroke: #fff;
}

@media (max-width: 580px) {
  .so-widget-industries-section-style-d75171398898-9 .section__industries {
    margin-right: -16px;
  }
}

.so-widget-industries-section-style-d75171398898-9 .industry__list {
  border-left: 1px solid #c7c7d6;
  display: flex;
}

@media (max-width: 767px) {
  .so-widget-industries-section-style-d75171398898-9 .section__arrows {
    position: absolute;
    left: 16px;
    bottom: 64px;
  }

  .so-widget-industries-section-style-d75171398898-9 .pl-big {
    padding-left: 0;
  }

  .so-widget-industries-section-style-d75171398898-9 .industry__list {
    margin-bottom: 56px;
  }
}

@media (max-width: 960px) {
  .so-widget-industries-section-style-d75171398898-9
    .industry__list:has(
      .industry__item.slick-current:not(:last-child):focus-visible
    ),
  .so-widget-industries-section-style-d75171398898-9
    .industry__list:has(.industry__item.slick-current:not(:last-child):hover) {
    border-color: transparent;
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .so-widget-industries-section-style-d75171398898-9
    .industry__list:has(.industry__item:nth-last-child(3):focus-visible),
  .so-widget-industries-section-style-d75171398898-9
    .industry__list:has(.industry__item:nth-last-child(3):hover) {
    border-color: transparent;
  }
}

@media (min-width: 960px) {
  .so-widget-industries-section-style-d75171398898-9
    .industry__list:has(
      .industry__item.slick-current:not(:nth-last-child(2)):focus-visible
    ),
  .so-widget-industries-section-style-d75171398898-9
    .industry__list:has(
      .industry__item.slick-current:not(:nth-last-child(2)):hover
    ) {
    border-color: transparent;
  }

  .so-widget-industries-section-style-d75171398898-9
    .industry__list:has(.industry__item:nth-last-child(4):focus-visible),
  .so-widget-industries-section-style-d75171398898-9
    .industry__list:has(.industry__item:nth-last-child(4):hover) {
    border-color: transparent;
  }
}

.so-widget-industries-section-style-d75171398898-9 .industry__list .slick-list {
  padding-right: 2px;
}

.so-widget-industries-section-style-d75171398898-9 .industry__item {
  border-right: 1px solid #c7c7d6;
  display: flex;
  flex-direction: column;
  flex: 0 0 85vw;
  height: 328px;
  overflow: hidden;
  outline: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  position: relative;
  text-decoration: none;
  transition: border-color 0.3s cubic-bezier(0.4, 0.2, 0.4, 1);
  width: 85vw;
}

@media (min-width: 580px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__item {
    flex-basis: 272px;
    width: 272px;
  }
}

@media (min-width: 768px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__item {
    flex-basis: 208px;
    width: 208px;
  }
}

@media (min-width: 960px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__item {
    flex-basis: 196px;
    width: 196px;
  }
}

@media (min-width: 1280px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__item {
    flex-basis: 256px;
    height: 384px;
    width: 256px;
  }
}

@media (min-width: 1440px) {
  .so-widget-industries-section-style-d75171398898-9 .section__arrows {
    gap: 12px;
  }

  .so-widget-industries-section-style-d75171398898-9 .industry__item {
    flex-basis: 276px;
    width: 276px;
  }
}

@media (min-width: 1680px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__item {
    height: 480px;
  }
}

@media (min-width: 1920px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__item {
    flex-basis: 376px;
    height: 600px;
    width: 376px;
  }
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:has(+ .industry__item:focus-visible),
.so-widget-industries-section-style-d75171398898-9
  .industry__item:has(+ .industry__item:hover) {
  border-color: transparent;
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible,
.so-widget-industries-section-style-d75171398898-9 .industry__item:hover {
  border-color: transparent;
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__image,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__mask,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:hover
  .industry__image,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:hover
  .industry__mask {
  clip-path: inset(0 0 0 0);
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__mask,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:hover
  .industry__mask {
  opacity: 1;
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__text,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__title,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:hover
  .industry__text,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:hover
  .industry__title {
  color: #fff;
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__text
  ul
  li:after,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:hover
  .industry__text
  ul
  li:after {
  background-color: #fff;
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__icon,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:hover
  .industry__icon {
  filter: brightness(0) invert(1);
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__icon-wrap,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:hover
  .industry__icon-wrap {
  background-color: transparent;
  border-color: #fff;
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__url,
.so-widget-industries-section-style-d75171398898-9
  .industry__item:hover
  .industry__url {
  opacity: 1;
}

.so-widget-industries-section-style-d75171398898-9
  .industry__item:focus-visible
  .industry__url {
  border-radius: 20px;
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-industries-section-style-d75171398898-9 .industry__image,
.so-widget-industries-section-style-d75171398898-9 .industry__mask {
  border-radius: 8px;
  bottom: 0;
  clip-path: inset(216px 16px 0 16px round 8px);
  height: 328px;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  transition: clip-path 0.3s cubic-bezier(0.4, 0.2, 0.4, 1);
  width: 100%;
}

@media (min-width: 1280px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__image,
  .so-widget-industries-section-style-d75171398898-9 .industry__mask {
    clip-path: inset(264px 16px 0 16px round 8px);
    height: 384px;
  }
}

@media (min-width: 1680px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__image,
  .so-widget-industries-section-style-d75171398898-9 .industry__mask {
    clip-path: inset(300px 16px 0 16px round 8px);
    height: 480px;
  }
}

@media (min-width: 1920px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__image,
  .so-widget-industries-section-style-d75171398898-9 .industry__mask {
    clip-path: inset(408px 16px 0 16px round 8px);
    height: 600px;
  }
}

@media (min-width: 2560px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__item {
    flex-basis: 496px;
    min-height: 656px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }

  .so-widget-industries-section-style-d75171398898-9
    .industry__item:focus-visible
    .industry__text
    ul
    li
    + li
    + li
    + li,
  .so-widget-industries-section-style-d75171398898-9
    .industry__item:hover
    .industry__text
    ul
    li
    + li
    + li
    + li {
    opacity: 1;
  }

  .so-widget-industries-section-style-d75171398898-9 .industry__image,
  .so-widget-industries-section-style-d75171398898-9 .industry__mask {
    clip-path: inset(416px 24px 0 24px round 8px);
    height: 656px;
  }
}

.so-widget-industries-section-style-d75171398898-9 .industry__mask {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: clip-path, opacity;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0.4, 1);
}

.so-widget-industries-section-style-d75171398898-9 .industry__content {
  height: 100%;
  position: relative;
}

.so-widget-industries-section-style-d75171398898-9 .industry__icon {
  height: 56px;
  transition: filter 0.3s;
  width: auto;
}

.so-widget-industries-section-style-d75171398898-9 .industry__icon-wrap {
  align-items: center;
  background-color: #F0EDEE;
  border-radius: 8px;
  border: 1px solid #F0EDEE;
  display: flex;
  height: 56px;
  justify-content: center;
  margin-bottom: 8px;
  overflow: hidden;
  transition-duration: 0.3s;
  transition-property: background-color, border-color;
  width: 56px;
}

@media (min-width: 1920px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__icon-wrap {
    margin-bottom: 16px;
  }
}

@media (min-width: 2560px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__icon-wrap {
    margin-bottom: 24px;
  }
}

.so-widget-industries-section-style-d75171398898-9 .industry__title {
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 1280px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__title {
    margin-bottom: 24px;
  }
}

@media (min-width: 1920px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__title {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    margin-bottom: 80px;
    min-height: 80px;
    visibility: visible;
    white-space: normal;
  }
}

@media (min-width: 2560px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__title {
    margin-bottom: 24px;
    min-height: 96px;
  }
}

.so-widget-industries-section-style-d75171398898-9 .industry__text {
  color: #54575e;
  line-height: 16px;
  margin-bottom: 16px;
  max-width: 200px;
}

@media (min-width: 768px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__text {
    max-width: 164px;
  }
}

.so-widget-industries-section-style-d75171398898-9 .industry__text ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 1280px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__text {
    line-height: 24px;
    max-width: 212px;
  }

  .so-widget-industries-section-style-d75171398898-9 .industry__text ul {
    line-height: 18px;
  }
}

.so-widget-industries-section-style-d75171398898-9 .industry__text ul li {
  padding-left: 24px;
  position: relative;
}

.so-widget-industries-section-style-d75171398898-9 .industry__text ul li:after {
  background-color: #2C666E;
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 6px;
  position: absolute;
  top: 3px;
  width: 8px;
}

@media (min-width: 1280px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__text ul li {
    padding-left: 28px;
  }

  .so-widget-industries-section-style-d75171398898-9
    .industry__text
    ul
    li:after {
    left: 8px;
    top: 5px;
  }
}

.so-widget-industries-section-style-d75171398898-9 .industry__text ul li + li {
  margin-top: 12px;
}

@media (max-width: 1919px) {
  .so-widget-industries-section-style-d75171398898-9
    .industry__item:focus-visible
    .industry__text
    ul
    li
    + li
    + li,
  .so-widget-industries-section-style-d75171398898-9
    .industry__item:hover
    .industry__text
    ul
    li
    + li
    + li {
    opacity: 1;
  }

  .so-widget-industries-section-style-d75171398898-9
    .industry__text
    ul
    li
    + li
    + li {
    opacity: 0;
  }
}

.so-widget-industries-section-style-d75171398898-9 .industry__text p {
  margin: 0;
}

.so-widget-industries-section-style-d75171398898-9 .industry__text p + p {
  margin-top: 1rem;
}

.so-widget-industries-section-style-d75171398898-9 .industry__url {
  bottom: 16px;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
}

@media (max-width: 767px) {
  .so-widget-experts-insights-style-d75171398898-9 .pl-big {
    padding-left: 0;
  }
}

.so-widget-experts-insights-style-d75171398898-9 .news {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

@media (min-width: 768px) {
  .so-widget-experts-insights-style-d75171398898-9 .news {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 24px;
  }
}

.so-widget-experts-insights-style-d75171398898-9 .news__item {
  background-color: #fff;
  border-radius: 8px;
  display: block;
  height: 264px;
  outline: 0;
  overflow: hidden;
  position: relative;
  text-decoration: none;
}

@media (min-width: 1280px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__item {
    height: 320px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__info {
    grid-column-gap: 16px;
    grid-template-columns: 1fr 1.3fr;
  }
}

@media (min-width: 1440px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__item {
    height: 336px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__info {
    grid-column-gap: 24px;
    grid-template-columns: 1fr 1.2fr;
  }
}

@media (min-width: 1680px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__text ul {
    line-height: 24px;
  }

  .so-widget-industries-section-style-d75171398898-9
    .industry__text
    ul
    li:after {
    top: 8px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__item {
    height: 400px;
  }
}

.so-widget-experts-insights-style-d75171398898-9
  .news__item:focus-visible
  .news__info,
.so-widget-experts-insights-style-d75171398898-9 .news__item:hover .news__info {
  color: #fff;
}

.so-widget-experts-insights-style-d75171398898-9
  .news__item:focus-visible
  .news__mask,
.so-widget-experts-insights-style-d75171398898-9 .news__item:hover .news__mask {
  opacity: 1;
}

.so-widget-experts-insights-style-d75171398898-9
  .news__item:focus-visible
  .news__imageWrap,
.so-widget-experts-insights-style-d75171398898-9
  .news__item:hover
  .news__imageWrap {
  clip-path: inset(0 0 0 0);
}

.so-widget-experts-insights-style-d75171398898-9
  .news__item:focus-visible
  .link-n:after,
.so-widget-experts-insights-style-d75171398898-9
  .news__item:focus-visible
  .link-n:before,
.so-widget-experts-insights-style-d75171398898-9
  .news__item:hover
  .link-n:after,
.so-widget-experts-insights-style-d75171398898-9
  .news__item:hover
  .link-n:before {
  filter: invert(1);
}

.so-widget-experts-insights-style-d75171398898-9
  .news__item:focus-visible
  .link-n {
  border-radius: 8px;
  outline-offset: 1px;
  outline: #2C666E solid 2px;
}

.so-widget-experts-insights-style-d75171398898-9 .news__info {
  bottom: 0;
  color: #07393C;
  display: grid;
  left: 0;
  padding: 16px;
  position: absolute;
  right: 0;
  transition: color 0.2s cubic-bezier(0.4, 0.2, 0.4, 1);
}

@media (min-width: 1920px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__text {
    margin-bottom: 32px;
  }

  .so-widget-industries-section-style-d75171398898-9
    .industry__text
    ul
    li
    + li {
    margin-top: 16px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news {
    grid-row-gap: 32px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__item {
    height: 448px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__info {
    grid-row-gap: 24px;
    grid-template-columns: 1.2fr 1fr;
    padding: 24px;
  }
}

@media (min-width: 2560px) {
  .so-widget-industries-section-style-d75171398898-9 .industry__text {
    line-height: 32px;
    margin-bottom: 40px;
    max-width: 264px;
  }

  .so-widget-industries-section-style-d75171398898-9 .industry__text ul {
    line-height: 32px;
  }

  .so-widget-industries-section-style-d75171398898-9
    .industry__text
    ul
    li:after {
    top: 13px;
  }

  .so-widget-industries-section-style-d75171398898-9
    .industry__text
    ul
    li
    + li
    + li
    + li {
    opacity: 0;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__item {
    height: 640px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__info {
    grid-template-columns: 1.5fr 1fr;
    padding: 32px;
    grid-column-gap: 32px;
  }
}

.so-widget-experts-insights-style-d75171398898-9 .news__image {
  background-color: #f8f8f9;
  left: 50%;
  min-height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  vertical-align: top;
  width: 100%;
}

.so-widget-experts-insights-style-d75171398898-9 .news__imageWrap {
  clip-path: inset(0 0 100px 0);
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: clip-path 0.2s cubic-bezier(0.4, 0.2, 0.4, 1);
}

.so-widget-experts-insights-style-d75171398898-9 .news__mask {
  background-color: rgba(0, 0, 0, 0.64);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0.2, 0.4, 1);
}

.so-widget-experts-insights-style-d75171398898-9 .news__type {
  align-items: center;
  background-color: #fff;
  border-radius: 24px;
  color: #07393C;
  display: flex;
  font-size: 14px;
  gap: 12px;
  line-height: 16px;
  padding: 4px 6px;
  position: absolute;
  right: 16px;
  top: 16px;
}

.so-widget-experts-insights-style-d75171398898-9 .news__type:after {
  border-radius: 50%;
  border: 1px solid #07393C;
  content: "";
  height: 10px;
  width: 10px;
}

.so-widget-experts-insights-style-d75171398898-9 .news__title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  margin: 0 0 8px;
  max-width: 400px;
  min-height: 40px;
  overflow: hidden;
  text-decoration: none;
  visibility: visible;
}

@media (min-width: 768px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__imageWrap {
    clip-path: inset(0 0 108px 0);
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__title {
    margin-bottom: 16px;
  }
}

@media (min-width: 1280px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__imageWrap {
    clip-path: inset(0 0 136px 0);
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__type {
    font-size: 15px;
    line-height: 24px;
    padding: 2px 8px;
    right: 16px;
    top: 16px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__title {
    grid-column: span 2;
    margin-bottom: 8px;
    max-width: 480px;
    min-height: 48px;
  }
}

@media (min-width: 1440px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__title {
    max-width: 100%;
    max-width: 520px;
  }
}

@media (min-width: 1920px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__imageWrap {
    clip-path: inset(0 0 144px 0);
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__type {
    font-size: 16px;
    gap: 16px;
    padding: 4px 10px;
    right: 24px;
    top: 24px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__title {
    grid-column: span 1;
    max-width: 560px;
    margin-bottom: 0;
  }
}

@media (min-width: 2560px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__imageWrap {
    clip-path: inset(0 0 184px 0);
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__type {
    font-size: 20px;
    line-height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__title {
    max-width: 640px;
    min-height: 64px;
  }
}

.so-widget-experts-insights-style-d75171398898-9 .news__author-block {
  display: none;
}

@media (min-width: 1280px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__author-block {
    display: inline-flex;
    order: 1;
    max-width: 300px;
  }
}

@media (min-width: 1680px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__author-block {
    max-width: 360px;
  }
}

.so-widget-experts-insights-style-d75171398898-9 .news__author-image {
  flex: 0 0 40px;
  margin-right: 12px;
}

.so-widget-experts-insights-style-d75171398898-9 .news__author-image img {
  border-radius: 50%;
  height: 40px;
  object-fit: cover;
  vertical-align: top;
  width: 40px;
}

.so-widget-experts-insights-style-d75171398898-9 .news__author-info {
  min-width: 0;
}

.so-widget-experts-insights-style-d75171398898-9 .news__author-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

@media (min-width: 1280px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__author-image {
    flex-basis: 48px;
    margin-right: 16px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__author-image img {
    height: 48px;
    width: 48px;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__author-name {
    font-size: 15px;
    line-height: 24px;
  }
}

@media (min-width: 1920px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__author-block {
    order: initial;
    max-width: initial;
    grid-row: span 2;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__author-name {
    font-size: 16px;
  }
}

.so-widget-experts-insights-style-d75171398898-9 .news__author-position {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

@media (min-width: 1280px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__author-position {
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__url {
    margin-top: 12px;
  }
}

@media (min-width: 1920px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__author-position {
    font-size: 16px;
    overflow: initial;
    white-space: normal;
  }

  .so-widget-experts-insights-style-d75171398898-9 .news__url {
    margin-top: 0;
  }
}

.so-widget-experts-insights-style-d75171398898-9 .news__all {
  margin-top: 24px;
}

@media (min-width: 1920px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__all {
    margin-top: 64px;
  }
}

@media (min-width: 2560px) {
  .so-widget-experts-insights-style-d75171398898-9 .news__all {
    margin-top: 80px;
  }
}

.so-widget-experts-insights-style-d75171398898-9 .link-n:after,
.so-widget-experts-insights-style-d75171398898-9 .link-n:before {
  transition: filter 0.2s cubic-bezier(0.4, 0.2, 0.4, 1);
}

